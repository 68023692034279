@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

:root {
  --bg-scale: 0.5818181818181818;
  --bg-margin: -0.7031249999999999rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */
html,
body {
  font-family: "Ubuntu", sans-serif !important;
  color: #1e1e1e;
  line-height: 1.3;
  background-color: #f5f5f5 !important;
  padding: 0;
  margin: 0;
  /* overflow-y: scroll; */
  font-size: 17.92px;
  /* display: block; */
  touch-action: manipulation !important;
  -webkit-font-smoothing: antialiased !important;
}

@media screen and (max-width: 992px) {
  html,
  body {
    font-size: 3.7333333333333334vw;
  }
}

img {
  max-width: 100%;
}
.text-center {
  text-align: center;
}
.d-block {
  display: block;
}
h1,
h2,
h3,
h4 {
  margin: 0;
}
ul li {
  list-style: none;
}
ul {
  padding: 0px;
  margin: 0px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e1e !important;
}

body {
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  position: relative;
}
.background-body {
  background-image: url("../images/velki-back.jpg");
  background-attachment: fixed;
}
.new-bg {
  position: fixed;
  top: 45%;
  left: 10%;
  background-attachment: fixed;
}

.main-container {
  width: 480px;
  background: #fff;
  margin: 0 auto;
  min-height: 100vh;
}
.main-outer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-container {
  width: 480px;
  background: #eef6fb;
  /* overflow-y: scroll !important; */
  min-height: 100vh;
  position: relative;
  /* overflow: hidden; */
}
.error-input {
  border: 1px solid red !important;
}

.leftpanel,
.rightpanel {
  flex: 1;
}

.leftpanel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-yellow {
  background-color: #ffc800 !important;
  border: none !important;
  color: #000 !important;
}

.text-yellow {
  color: #ffc800;
}
.d-flex {
  display: flex;
}

.header .logo-sec {
  max-width: 129px;
  height: 100%;
  flex: 0 0 129px;
}

.header .logo-sec button {
  padding: 0;
  margin-left: 4px;
}

.header .logo-sec img {
  /* height: 25.19px; */
  height: auto;
  width: 6rem;
}
.header {
  align-items: center;
  position: absolute;
  top: 0;
  height: 2.857142857142857rem;
  font-size: 0.7857142857142857rem;
  z-index: 11;
  /* min-width: 480px; */
  width: 100%;
}
.header-font-size {
  font-size: 0.7857142857142857rem !important;
}
.header.before-login {
  padding: 6px !important;
}
.header .social-header {
  flex: 1 1;
  text-align: right;
  display: flex;
  justify-content: end;
}

.header .social-header a {
  background: #0000007a;
  /* padding: 2px 11px !important; */
  text-decoration: none;
  margin-right: 13px;
  border-radius: 5px;
  color: #fff;

  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  height: 1.8571428571rem;

  font-family: inherit;
  /*    
    font-weight: inherit;
    line-height: inherit;
    color: inherit; */
}
.header .social-header a img {
  vertical-align: text-bottom;
  height: 16px;
}

.header .social-header a:last-child {
  margin: 0;
}

.header .social-header a.login {
  background: #e20000;
  padding: 0.75rem;
}
.header .social-header a.signup {
  padding: 2px 6px;
}

.home-banner-sec {
  position: relative;
}

.home-banner-sec .slick-slide img {
  display: block;
  /* height: 96px; */
}

.home-banner-sec .slick-prev {
  left: 14px;
  z-index: 2;
}

.home-banner-sec .slick-next {
  right: 14px;
}

.audio-trakcer {
  background-color: white;
  padding: 0 9px;
  display: flex;
  border-right: 1px solid rgb(212 224 229/1) !important;
}
.sport-header-inner {
  width: 100px;
}

.marquee-notification {
  align-items: center;
  padding: 0.1rem 0;
  background: white;
}

.marquee-notification marquee {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}
.marquee-notification i.icon-mic {
  font-size: 1.1428571428571428rem;
}
.games-slot .slot1 {
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 4px;
  width: 70px;
  text-align: center;
}

.slot1:active {
  background-color: #3d7489;
  border-radius: 0 !important;
}

.swiper-slide {
  width: 70px !important;
}
.games-slot {
  background: #1b1f23;
  /* padding: 0.5rem 0px 16px 0px; */
  padding: 8px 10px;
  color: #fff;
  padding-bottom: 18px;
}

/* 
.slotactive {
  background: #45494d;
} */
.tab-indicator-new {
  position: absolute;
  /* width: 84px;
  height: 79px; */
  top: 10%;
  background-image: linear-gradient(
    90deg,
    rgba(168, 177, 202, 0.2),
    rgba(245, 246, 250, 0.2)
  );
  border-radius: 9px !important;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

/* @keyframes fadeBackground {
  from { background-color: #45494d; }
  to { background-color: #1b1f23 }
} */

.games-slot .slot1:focus {
  background: #3d7489;
}

.games-slot span {
  display: block;
  color: #ffffff73;
  /* font-weight: 600; */
  font-size: 0.8571428571428571rem !important;
}
.games-slot svg {
  font-size: 40px;
  margin: 0 auto;
}

/* .games-slot ul {
  display: flex;
  align-items: center;
} */

.games-slot .slot1 img {
  max-width: 48px;
  margin: 0 auto;
}

.games-inner {
  display: flex;
}
span.icon-list {
  bottom: 10%;
  position: fixed;
  left: 5%;
  border-radius: 50%;
  background: #1b1f23;
  width: 3.2142857142857144rem !important;
  height: 3.2142857142857144rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5714285714285714rem;
  color: #fff;
}
span.icon-list span {
  position: absolute;
  top: 10%;
  font-size: 0.6428571428571429rem;
  left: 60%;
  background-color: red;
  color: white;
  padding-left: 3px;
  border-radius: 50%;
  min-width: 1.0714285714rem;
  min-height: 1.0714285714rem;
  padding-top: 3px;
}
.games-inner {
  display: flex;
  padding: 12px 6px 0px 6px !important;
  background: aliceblue;
}

.games-inner aside {
  max-width: 3.6428571429rem !important;
  flex: 0 0 53px;
  margin-right: 6.72px !important;
  padding: 4px 2px !important;
  box-shadow: 0 1px 4px 2px rgb(0 0 0 / 3%);
  border-radius: 6px;
  background-color: #fff;
  height: 100%;
}
.games-inner aside li svg {
  font-size: 30px;
  opacity: 0.7;
}
.games-inner aside li.active svg {
  opacity: 1;
}
.games-inner aside li {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 11px;
  cursor: pointer;
  margin-bottom: 15.38px;
  padding: 0.6rem 0px !important;
  height: 4.5rem;
}
.games-inner aside li.active {
  background-color: #ffc800;
  border-radius: 0.5rem;
  transition-duration: 1s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.games-inner aside li span {
  color: #1b1f23;
  display: block;
  margin-top: 8px;
  font-size: 0.6428571428571429rem;
}
.games-wrapper {
  flex: 1 1;
  padding: 3px 1px 3px 6px;
}
/* .games-card-inner{
  display: flex;
  flex-wrap: wrap;
} */

.row {
  display: flex;
  flex-wrap: wrap;
}

.games-wrapper .game-card {
  width: calc(33.33% - 8px);
  margin: 4px 4px;
  padding: 0px;
}
.game-card {
  border-radius: 0.75rem;
  overflow: hidden;
  cursor: pointer;
}

.game-card img {
  width: 100%;
  height: 6.785714285714286rem;
  object-fit: cover;
}
main {
  overflow-y: hidden;
  min-height: 100vh;
  padding-top: 2.857142857142857rem;
  overflow-x: hidden;
}

.games-card-inner {
  padding: 10px 20px;
  margin-bottom: 12px;
  background-image: linear-gradient(-180deg, #d4e0e5, #eef6fb);
}
.games-card-inner h2 {
  font-size: 16px;
  margin-bottom: 8px;
  border-left: 3px solid #000;
  padding-left: 5px;
  font-weight: bold;
}

.lazy-load-image-background {
  width: 100%;
}

.games-wrapper .card {
  box-shadow: 0 1px 6px 4px rgba(0, 0, 0, 0.06);
  padding: 6px 16px !important;
  border-radius: 9px;
  background-position: right;
  background-size: cover;
  border: none;
  margin-bottom: 0.5rem !important;
  height: 6.857142857142857rem;
}

.games-wrapper .card span {
  font-size: 1.0714285714285714rem !important;
  font-weight: 700;
  opacity: 0.8;
}

.games-wrapper .card strong {
  font-size: 3.4285714285714284rem !important;
  font-family: inherit;
  font-weight: 700;
  line-height: 50px;
}
.games-wrapper .card.card1 {
  background-image: url(../images/banner1.jpg);
}
.games-wrapper .card.card2 {
  background-image: url(../images/banner2.jpg);
}
.games-wrapper .card.card3 {
  background-image: url(../images/banner3.jpg);
}
.games-wrapper .card.card4 {
  background-image: url(../images/banner4.jpg);
}

.games-inner aside li:last-child {
  margin: 0;
}

.footer {
  padding: 0px 12px 14px 12px;
  margin-top: 2.857142857142857rem;
}

.footer li {
  display: inline-block;
  padding: 6px 9px;
  font-size: 0.7857142857rem;
  line-height: 5px;
  border-right: 1px solid rgb(212 224 229/1);
}

.footer li:last-child {
  border: none;
}

.footer li span {
  cursor: pointer;
}

.social-icon {
  text-align: center;
  margin: 10px 0px;
}

.social-icon i {
  font-size: 2.142857142857143rem;
  margin: 0px 11px;
  /* width: 27.53px; */
}

.social-icon a {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.social-icon a.whatsapp svg {
  font-size: 39px;
}

.download-apk img {
  max-width: 157px;
}
.download-apk span {
  font-size: 0.7857142857rem;
  color: rgb(111 136 152/1);
  font-weight: 600;
}
/* login-panel */

.login-panel {
  background-image: url(../images/velki-bg-login.jpg);
  background-size: cover;
  background-position: center;
}
.login-panel-without {
  background-image: none;
  background-color: rgb(238 246 251/1) !important;
}

.login-panel .fs-2 {
  font-size: 1.7857142857142858rem !important;
}

.login-logo {
  max-width: 185px;
  margin: 20px auto 0.5rem;
  height: 4.285714285714286rem;
}

button.theme-btn {
  background: #ffc800;
  border-color: #ffc800;
  color: #000;
  padding: 9px 32px;
  font-size: 20px;
  font-weight: 600;
  transition: 0.5s;
}
.login-panel .form-floating .form-control {
  /*  */
  border: 1px solid rgb(111 136 152/1);
  padding: 0.5rem 0.75rem 0.5rem 42px;
  height: 2.857142857142857rem;
  min-height: 2.857142857142857rem;
  border-radius: 0.5rem;
}
.form-floating > label {
  color: rgb(111 136 152/1);
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 10px !important;
  padding-left: 2.5rem !important;
}
.login-panel .form-floating label {
  margin-left: 37px;
  /*  */
  opacity: 0.8;
  padding: 9px 0rem;
  font-size: 13.44px;
}
.login-panel .update-p label {
  margin-left: 17px;
}

label.form-check-label {
  font-size: 1rem;
  margin-left: -8px;
  vertical-align: top;
}

.login-panel .form-floating svg {
  margin-left: 12px;
  opacity: 0.5;
  font-size: 16px;
}

.login-panel .form-floating .form-control:focus {
  box-shadow: none;
  border: 2px solid #c0a024;
}
.login-panel .form-floating .form-control:focus ~ svg {
  color: #c0a024;
  opacity: 1;
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1 !important;
  transform: translateY(-1.5rem) translateX(0.15rem);
  max-width: max-content;
  background: #fff;
  padding: 0px 0px;
  height: auto;
  margin-left: 38px;
  font-size: 0.8571428571428571rem;
  color: #c0a024 !important;
}

button.theme-btn:hover {
  background: transparent;
  border-color: #ffc800;
  color: #000;
}
.login-panel .form-floating input[type="password"] {
  padding-right: 49px;
}
.cursor-pointer {
  cursor: pointer;
}
/* login-panel */

.sidebar-wrapper {
  position: absolute;
  background: aliceblue;
  left: 0%;
  transition: all 2s ease;
  top: 0;
  bottom: 0;
  z-index: 999;
  width: 341px;
  padding: 13px;
  height: 100vh;
  overflow-y: scroll;
}
.open {
  left: -100%;
  position: absolute;
  top: 0;
  transition: all 2s ease;
}
.sidebar-wrapper ul {
  background: #fff;
  border-radius: 13px;
  overflow: hidden;
}

.sidebar-wrapper ul a {
  display: flex;
  padding: 10px;
  align-items: center;
  color: #000;

  /* font-size: 17px; */
  background-image: url(../images/next.png);
  background-repeat: no-repeat;
  background-position: right 6px top 5px;
}

.sidebar-wrapper ul a svg {
  margin-right: 8px;
  font-size: 15px;
}

.sidebar-wrapper ul li {
  border-bottom: 1px solid #0000001f;
}

.sidebar-wrapper ul li:last-child {
  border: none;
}

.overlay {
  position: fixed;
  background: #0007;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  right: 0;
  left: 0;
  bottom: 0;
}
.pop-wrap,
.select-list {
  width: 97vw;
  margin: auto;
  margin-top: 1%;
}

.dfx {
  display: flex;
  justify-content: space-between;
}
.sabaimg {
  width: 100%;
  border: 2px solid #c5c325;
  border-radius: 5px;
  margin-top: 3px;
}

.btn-list {
  padding: 0;
  flex-wrap: wrap;
}
.btn-list li a {
  height: 10.93333vw;
  line-height: 10.93333vw;
}
.btn-send {
  background-image: linear-gradient(-180deg, #464646 15%, #121212 100%);
  border-color: #000;
  color: #ffb80c;
  display: block;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 1.6vw;
  font-size: 4vw;
  font-weight: bold;
  text-decoration: none;
}
.btn-list li {
  flex: 1 1 auto;
  margin: 1.6vw 0 0;
  overflow: hidden;
}
.top-sidebar strong {
  font-size: 1.0714285714285714rem;
}

.sidebar-wrapper ul a:hover {
  color: #a1841aed;
}

.canvas-img {
  max-width: 7.142857142857143rem;
  right: 0px;
  margin-top: -3px;
}

.form-check {
  font-size: 18px;
}

.login-panel input[type="checkbox"] {
  border-color: #c0a024;
  width: 13px;
  height: 13px;
}
.login-panel input[type="checkbox"]:focus {
  outline: none;
  box-shadow: none;
}

.login-panel input[type="checkbox"]:checked {
  color: #000;
  border-color: #c0a024;
}
.balance-label-detail {
  background: #000;
  color: #fff;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  text-align: center;
  height: 2.4285714285714284rem;
  margin-top: -1px;
}
.balance-label-detail span {
  font-size: 1rem;
  line-height: 22px;
}
.balance-label {
  background: black;
  color: #fff;
  padding: 11px 10px;
  text-align: center;
  font-size: 1rem;
  height: 3rem;
}
.balance-label-inner span {
  font-size: 0.7142857142857143rem;
}
.low-button {
  font-weight: 700;
  padding: 3px 6px;
  font-size: 0.7142857142857143rem;
}
.balance-sec {
  background: #262c32;
  color: #fff;
  padding: 14px;
  border-radius: 14px;
}

.balance-sec span {
  display: inline-block;
  background: #ffc800;
  color: #000;
  padding: 4px 5px;
  font-size: 14px;
  border-radius: 6px;
  font-weight: 700;
}

.balance-deposit-sec table th {
  background-color: rgb(212 224 229/1);
}
.master-pass {
  position: relative;
  padding-left: 15px;
  margin-left: 10px;
  font-weight: 600;
}
.master-pass::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #9cb1bd;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.active-log-table table th {
  background: #d5f2b8;
}
.active-log-table table .success {
  color: #73be24;
}

.login-panel main {
  padding-top: 0px;
}

/* website-scrollbar */

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* website-scrollbar */

.select-container {
  background: #262c32;
  padding: 8px;
  text-align: center;
}

.select-container select {
  width: 100%;
  padding: 7px 10px;
  background: transparent;
  border-color: #ffc800;
  color: #ffc800;
  font-size: 19px;
  font-weight: 500;
  border-radius: 5px;
}
select option {
  background: #262c32;
  padding: 20px;
}

.select-container select:focus {
  outline: none;
}

.inner-sidebar-content {
  background: aliceblue;
  min-height: calc(100vh - 106px);
}

ul.list-outside li {
  list-style: disc;
}

.wallet-form-control input.form-control {
  padding: 11px 12px;
  background: #d4e0e5;
  font-size: 19px;
  font-weight: 600;
}
.wallet-form-control {
  position: relative;
}
.overlay-wallet {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(38 44 50/0.8);
  width: 100%;
  color: rgb(255 255 255/1);
  font-weight: 700;
  height: 100%;
  border-radius: 10px;
}
.p2transfer-form .form-label {
  font-weight: 600;
  font-size: 19px;
}
.p2transfer-form.login-panel {
  background-image: none;
}

.p2transfer-form.login-panel label {
  margin-left: 0px;
}

.p2transfer-form.login-panel .form-floating > .form-control-plaintext ~ label,
.p2transfer-form.login-panel .form-floating > .form-control:focus ~ label,
.p2transfer-form.login-panel
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label,
.p2transfer-form.login-panel .form-floating > .form-select ~ label {
  opacity: 1 !important;
  transform: translateY(-0.7rem) translateX(0.15rem);
  max-width: max-content;
  background: #fff;
  margin-left: 29px;
  padding: 0px 8px !important;
  height: auto;
  margin-left: 5px;
}

.p2transfer-form.login-panel .form-floating label {
  padding: 9px;
}

.p2transfer-form .form-floating > .form-control-plaintext:focus,
.p2transfer-form.form-floating
  > .form-control-plaintext:not(:placeholder-shown),
.p2transfer-form .form-floating > .form-control:focus,
.p2transfer-form .form-floating > .form-control:not(:placeholder-shown) {
  padding-left: 18px !important;
  height: 100% !important;
}

.p2transfer-form.login-panel .pbu-floating label {
  margin-left: 58px;
}

.p2transfer-form.login-panel
  .pbu-floating
  .form-floating
  > .form-control-plaintext:focus,
.p2transfer-form.login-panel
  .pbu-floating
  .form-floating
  > .form-control-plaintext:not(:placeholder-shown),
.p2transfer-form.login-panel .pbu-floating .form-floating > .form-control:focus,
.p2transfer-form.login-panel
  .pbu-floating
  .form-floating
  > .form-control:not(:placeholder-shown) {
  padding-left: 73px !important;
  height: 100% !important;
}

.p2transfer-form.login-panel
  .pbu-floating
  .form-floating
  > .form-control-plaintext
  ~ label,
.p2transfer-form.login-panel
  .pbu-floating
  .form-floating
  > .form-control:focus
  ~ label,
.p2transfer-form.login-panel
  .pbu-floating
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label,
.p2transfer-form.login-panel
  .pbu-floating
  .form-floating
  > .form-select
  ~ label {
  margin-left: 69px;
}
.pbu-floating span {
  border-right: 1px solid #00000026;
}

.setting-panel .form-check input[type="checkbox"] {
  border-color: #c0a024;
  /* background: #c0a02433; */
  font-size: 32px;
  float: right;
}
.setting-panel .form-check {
  padding-left: 0px;
}
.setting-panel .form-check input[type="checkbox"]:checked {
  background-color: #ffc800;
  border-color: #00000026;
}

.sports-tab-panel h3 {
  font-size: 0.9285714285714286rem !important;
  position: relative;
  font-weight: 700;
  text-align: center;
}

.sports-tab-panel ul li {
  font-size: 18px;
  padding: 10px 16px;
  font-weight: 700;
}

.sports-tab-panel ul li:first-child {
  padding-left: 0px;
}

::-webkit-scrollbar {
  height: 2px;
}
.sports-tab-panel h3 {
  margin-bottom: 0px !important;
}
.sports-tab-panel h3.active::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 20px;
  right: 0;
  margin: 0 auto;
  border-radius: 0.15rem;
  width: 73px;
  background: #1e1e1e;
  height: 0.29rem;
}

.sports-tab-panel .slick-list {
  height: 30px !important;
}
.sports-tab-panel {
  background: rgb(212 224 229/1);
}
/* 1/6/23 */

.select-container .dropdown-toggle {
  width: 100%;
  background: transparent !important;
  border-color: #ffc800;
  color: #ffc800;
  font-size: 1.0714285714285714rem;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.142857142857143rem;
}

.select-container .dropdown-menu {
  width: 100%;
  background: #262c32;
  color: #ffc800;
  border-radius: 0px;
  border-radius: 0px 0px 8px 7px;
  border: 1px solid #ffc800;
  border-top: 0;
  margin-top: -7px;
}

.select-container .dropdown-toggle:active,
.select-container .dropdown-toggle:hover,
.select-container .dropdown-toggle.show {
  /* background: transparent!important; */
  color: #ffc800 !important;
  border-color: #ffc800 !important;
}

.select-container .dropdown-toggle::after {
  background-image: url(../images/arrow.png) !important;
  border: 0;
  width: 23px;
  height: 15px;
  background-size: 18px;
  background-repeat: no-repeat;
  transition: 0.3s;
}
.select-container .dropdown-toggle.show::after {
  transform: rotate(180deg);
}

.select-container .dropdown-menu a {
  color: #ffc800;
  font-size: 19px;
  font-weight: 500;
  padding: 4px 12px;
}
.select-container .dropdown-menu a.dropdown-item:hover {
  background: #6f8898;
}

.select-container.bet-history-dropdown .dropdown-item {
  padding-left: 103px;
}

/* 1/6/23 */

.order-checkbox label.text-yellow {
  font-size: 1.0714285714285714rem;
  margin-right: 13px;
}

.order-checkbox .form-check {
  margin-right: 9px;
  margin-bottom: 0;
}

.order-checkbox .form-check input[type="checkbox"] {
  margin-right: 12px;
}

.order-checkbox .form-check:last-child {
  margin-right: 0px;
}

/* 2/6/23 */

.current-bets-table .odds-table th {
  background: #d4e0e5;
  padding-left: 26px;
  font-weight: normal;
  color: #000;
}

/* .current-bets-table th .master-pass:first-child {
  font-weight: 600;
} */

.current-bets-table .lay-back td {
  padding-left: 25px;
  padding: 14px 19px;
  font-weight: 700;
}

.bet-name {
  padding: 6px 13px;
  border-radius: 22px;
  color: #000;
  margin-right: 0px;
  font-weight: 700;
}

.backmd {
  background: #7cc3f5 !important;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 3px !important;
  width: 5rem;
  height: 2rem;
  font-size: 0.9285714285714286rem !important;
}
.laymd {
  background: #faa9ba !important;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 3px !important;
  width: 5rem;
  height: 2rem;
  font-size: 0.9285714285714286rem;
  justify-content: center;
}

.market-depth-body .to-back-to-lay table td {
  text-align: center !important;
}

.current-bets-table {
  /* border-bottom: 8px solid #9cb1bd; */
  border-bottom: 8px solid #d4e0e5;
  /* border-left: 1px solid #d4e0e5;
  border-right: 1px solid #d4e0e5; */
  /* border: 1px solid #d4e0e5; */
  border-radius: 14px;
  overflow: hidden;
}
.current-bets-table.show {
  border-color: #9cb1bd;
}

.bets-table thead {
  background: #9cb1bd;
}

.bets-table thead th {
  padding: 5px 11px;
}
.current-bets-table td {
  width: 33.33%;
}

.show-hide-bet {
  background: #d4e0e5;
  border: none;
  padding: 2px 47px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px 0px 14px 14px;
}

.show-hide-bet.arrow-up {
  background: rgb(156 177 189/1);
}
.show-hide-bet svg {
  font-size: 21px;
  transition: 0.4s;
}
.show-hide-bet.arrow-up svg {
  transform: rotate(180deg);
}
.all-bets {
  margin-bottom: 32px;
}

.bet-name.back {
  background: rgb(161 210 244/1);
  font-size: 0.9285714285714286rem;
  margin-right: 5px;
}

.bet-name.lay {
  background: rgb(252 198 209/1);
  font-size: 0.9285714285714286rem;
  margin-right: 5px;
}
.casino-category .category-item > div {
  text-align: center;
}
.bet-bottom td {
  font-size: 0.8571428571428571rem !important;
  padding: 0.3rem 0.5rem !important;
  border-width: 0 !important;
}
.bet-bottom-profit td {
  font-size: 0.7142857142857143rem !important;
  padding: 0.2rem 0.4rem !important;
}

.bet-bottom td:last-child {
  color: rgb(90 94 98/1) !important;
}

.casino-category {
  padding: 14px 8px 6px 8px;
  color: #946f3b;
  background-image: url(../images/casino-back.png);
  background-size: contain;
}

.casino-category .slick-list {
  padding-bottom: 11px;
}
.subcasino {
  background: #333333;
  color: #fff;
  padding: 10px;
}

.subcasino span {
  display: block;
  padding: 6px 15px;
  border-radius: 7px;
  margin-right: 14px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
.subcasino span.active {
  background: #ffc800;
  color: #000;
}

.casino-category img {
  transition: 0.5s;
  margin: 0 auto;
}
.casino-category .category-item > div {
  cursor: pointer;
}
.casino-category .category-item > div h6 {
  font-weight: 700;
  margin-bottom: 13px;
  color: #946f3b !important;
}
.casino-category .category-item > div.active img {
  transform: scale(1.1);
}
.casino-category .category-item > div.active h6 {
  color: #e7bb6f !important;
}

.casinofilter {
  background: aliceblue;
  padding: 10px 10px;
}
.casinofilter span {
  padding: 7px 14px;

  font-weight: 600;
  border-radius: 24px;
  cursor: pointer;
  margin-right: 22px;
  position: relative;
}
.casinofilter span.active {
  background-color: #ffc800;
  color: #000;
}
.casinofilter span:last-child::after {
  border: none;
}
.casinofilter span::after {
  position: absolute;
  content: "";
  border: 1px solid #0000002b;
  height: 20px;
  top: 3px;
  right: -11px;
  color: #000;
}

/* 2/6/23 */

/* 6/6/23 */

.casino-all-games .game-card {
  border-radius: 0px 22px 0px 22px;
}
.casino-all-games .games-card-inner h2 {
  border-left: 0;
  background: #ffc800;
  width: max-content;
  padding: 7px 53px 10px 15px;
  margin-left: -35px;
  border-radius: 0px 25px 25px 0px;
  /* padding: 0px; */
  font-weight: 700;
  font-size: 18px;
  position: relative;
  height: 2.142857142857143rem;
}
.casino-all-games .games-card-inner h2::after {
  content: "";
  position: absolute;
  left: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.5rem 0.4285714286rem 0;
  border-color: transparent #000000 transparent transparent;
  bottom: -6px;
}

.bottom-navigation ul {
  display: flex;
  justify-content: space-between;
}
.bottom-navigation {
  background: #fff;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  border-top: 1px solid #00000024;
}
.bottom-navigation ul li {
  padding: 6px 20px;

  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.bottom-navigation div {
  max-width: 37px;
  margin: 0 auto;
  display: block;
}
.bottom-navigation div img {
  height: 27px;
}
.bottom-navigation ul li.active {
  background: #ffc800;
}

main.showbottomnavigation {
  height: calc(100vh - 88px);
}

.main-casino-wrapper {
  background-color: aliceblue;
}

.main-casino-wrapper h6 {
  font-size: 1.0714285714rem;
  color: #000;
  font-weight: 700;
}

.main-casino-wrapper .games-card-inner {
  background: #dce6eb;
  padding: 10px 20px;
  margin-bottom: 12px;
  border-radius: 0px 30px 0px 30px;
  margin: 0px 9px;
  margin-bottom: 30px;
}
.table-data {
  position: absolute;
  width: 100px;
  height: 22px;
  background-image: url(../images/batch.svg);
  background-repeat: no-repeat;
  top: 17px;
}

.table-data span {
  text-shadow: 1px 1px 0 rgba(255, 230, 183, 0.42),
    -1px -1px 0 rgba(255, 230, 183, 0.42), 1px -1px 0 rgba(255, 230, 183, 0.42),
    -1px 1px 0 rgba(255, 230, 183, 0.42);
  font-size: 8px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 7px;
}

.game-menu {
  background: #333333;
  color: #fff;
  padding: 8px 14px;
}

.game-menu ul {
  display: flex;
}
.game-menu ul li.active {
  background: #ffc800;
  color: #000;
  font-weight: 700;
}
.game-menu ul li {
  color: #fff;
  /* margin-right: 15px; */
  padding: 6px 13px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  margin-left: 0.7814285714285714rem;
  align-items: center;
}
.game-menu ul li svg {
  font-size: 25px;
  margin-right: 6px;
}
.match-list ul {
  background: #fff;
  border-radius: 0.5rem 0.5rem 0 0;
}

.w-100.mb-3.match-list {
  margin-bottom: 0 !important;
}

.w-100.mb-3.match-list .flex.flex-1.items-center {
  font-size: 0.5714285714rem;
  color: #000;
  background: #e2eaef;
  display: inline-flex;
  width: auto;
}

.match-list ul li {
  padding: 15px 26px 15px 12px;
  width: 100%;
  font-size: 19px;
  border-bottom: 1px solid #00000026;

  font-weight: 500;
  cursor: pointer;
  background-image: url(../images/next.png);
  background-repeat: no-repeat;
  background-position: right;
  font-family: "Ubuntu";
}

.match-list aside {
  width: 59px;
}
.match-list ul li:last-child {
  border: none;
}

.match-list h6 {
  font-size: 18px;
}

.leage-list aside {
  width: 59px;
  padding: 0px 12px 0px 5px;
}

.leage-list aside li {
  background: #7e919f;
  border-radius: 100%;
  margin-bottom: 28px;
  width: 30px;
  height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: relative;
}

.leage-list aside li::after {
  content: "";
  position: absolute;
  bottom: -16px;
  background: #7e919f;
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.exchange-label {
  background-color: rgb(30 30 30/1);
}

.fancy-sport-tab {
  border-radius: 16px;
  overflow: hidden;
}

.fancy-sport-header {
  background: #000;
  border-radius: 10px 10px 0px 0px;
  padding-top: 12px;
}
.fancy-sport-header button {
  background: transparent;
  border: none;
  /*  */
  border-radius: 7px 7px 0px 0px;
  /* padding: 7px 23px; */
  width: 7.142857142857143rem;
  text-align: center;
}
.fancy-sports-inner-tab {
  background: #ffc800;
  color: #000;
  /* padding: 0px 8px; */
}
.fancy-tab-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fancy-sport-header button:hover,
.fancy-sport-header button:active {
  background: transparent !important;
}
.fancy-sport-header .taber {
  background: #735a08;
  margin: 0px 11px;
  border-radius: 10px 10px 0px 0px;
  width: max-content;
}
.fancy-sport-header .taber button {
  color: #ffc800;
}
.fancy-sport-header button.active-taber {
  background: #ffc800;
  color: #000;
}
.fancy-sports-inner-tab button {
  color: #000;
  font-weight: 600;
}
/* 6/6/23 */

/* 7/7/23 */

.sport-listing .accordion-item {
  border: none;
}

.sport-listing .accordion-button {
  background: #eef6fb;
  /* border-radius: 8px !important; */
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-weight: 700;
  opacity: 1;
  font-size: 0.9285714285714286rem !important;
  padding: 0.5rem 0.4rem !important;
}
.sport-listing .accordion-item {
  margin-bottom: 2px;
}

.sport-listing {
  background: white;
  padding: 4px;
}

.sport-listing .accordion-button::after {
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: 15px;
  transition: var(--bs-accordion-btn-icon-transition);
}

.sports-listing-score {
  margin-top: 2px;
  /* padding: 4px; */
}
.sport-listing .accordion-button:focus {
  box-shadow: none;
}

.sports-listing-score .team-wise-score {
  padding-top: 12px;
  margin-bottom: 3px;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
  line-height: 15px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgb(161 210 244/1);
}
.sports-listing-score .parly-wise-score {
  background-color: rgb(212 224 229/1);
  height: 3.5rem !important;
  padding-top: 5px !important;
}
.sports-listing-score {
  position: relative;
}

.sports-listing-score .team-wise-score span {
  font-size: 0.7142857143rem;
  font-weight: 700;
}
.postion-with-height {
  height: 61px !important;
}
.sports-listing-score .team-wise-score strong {
  font-size: 1.0714285714285714rem !important;
}

.sport-listing .accordion-button:not(.collapsed) {
  background-color: #eef6fb;
  color: #000;
  box-shadow: none;
  /* font-size: 12.48px !important; */
}
.sport-listing .accordion-button::after {
  filter: brightness(0);
}

.sport-listing .accordion-button svg {
  height: 18px;
}

.bg-star-sprit-black {
  background-image: url(../images/mypin-star-sprit-black.png);
}
.fave {
  width: 5rem;
  max-width: 70px;
  height: 3.2142857143rem;
  max-height: 45px;
  transform-origin: center center;
  background-position: 0 0;
  transform: scale(0.5);
  /* margin: var(--bg-margin); */
  margin-left: -20px !important;
}
.fave.animate {
  background-position: -3519px 0;
  transition: background-position 0.8s steps(55);
}

.match-odd-table li {
  background: #eef6fb;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 10px;
  align-items: center;
  margin-bottom: 2px;
}

.match-odd-table li > div {
  display: flex;
  text-align: center;
  width: 31.2%;
  gap: 0.125rem;
}

.match-odd-table li h2 {
  font-size: 0.9285714285714286rem;
  margin: 0;
  font-weight: 700;
  padding-right: 10px;
}
/* .lay-back-table{
  position: relative;
} */
.lay-back-table .back {
  background-color: #7cc3f5;
}
.lay-back-table .back strong {
  font-size: 1.0714285714rem;
  line-height: 10px;
}
.lay-back-table .lay strong {
  font-size: 1.0714285714285714rem;
  line-height: 10px;
}

.d-flex.back-lay-head.justify-content-end.ms-auto.pb-1 {
  line-height: 14px;
  width: 104px;
  /* font-size: 9.6px; */
}
.lay-back-table .lay {
  background-color: #faa9ba;
}
.match-odd-table li > div > div {
  /* margin-right: 3px; */
  background: #000;
  border-radius: 6px;
  /* font-size: 13px !important; */
  width: 68px;
  height: 2.5714285714rem !important;
}
.match-odd-table li > div > div:last-child {
  margin: 0;
}
.match-odd-table li > div > div:last-child strong {
  font-size: 1.0714285714285714rem;
  line-height: 10px;
}

.lay-back-table .d-block {
  line-height: 8px;
  font-size: 0.6428571429rem;
}

.bg-bookmaker-market {
  background: linear-gradient(
    90deg,
    rgb(124, 195, 245) 0%,
    rgb(196, 171, 238) 54%,
    rgb(250, 169, 186) 100%
  );
  padding: 4px;
  border-radius: 7px;
  /* width: 118px !important ; */
}

.match-odd-table .bg-bookmaker-market div {
  height: 32.9531px !important;
}
.match-odd-table ul li {
  position: relative;
}

.bg-bookmaker-market > div {
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookmaker .overlay-match {
  width: 31% !important;
}
.fancy-sport-tab .match-odd-table .overlay-match {
  width: 31% !important;
}
.sport-home .overlay-match {
  width: 104px !important;
}
.overlay-match {
  background: rgb(90 94 98/1);
  width: 31%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 6px;
  opacity: 0.8;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7857142857142857rem;
}
.market-depth-sec button {
  background-color: #000;
}
.market-depth-sec button img {
  max-width: 18px;
  margin-right: 4px;
}
.market-depth-sec button {
  background-color: #000;
  padding: 2.5px 6px;
  font-size: 12px;
}
.market-depth-sec button:hover {
  background-color: #000;
  border-color: #000;
}
.min-max svg {
  background: #6f8898;
  border-radius: 100%;
  padding: 4px;
  color: #fff;
  font-size: 14px;
  margin-right: 5px;
}
.info-btn svg {
  color: #8b6b05;
}
.info-btn {
  background: #ffc800 !important;
  z-index: 10;
  margin-right: 7px;
}
.min-max span {
  margin-right: 5px;
  font-size: 12.56px;
}
.min-max strong {
  font-size: 10.56px;
}
.min-max {
  font-family: "Lato";
  color: #6f8898;
}

.match-odd-header {
  position: relative;
}

.match-odd-header::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.215rem;
  background: #000;
  bottom: 4px;
  left: 0;
  border-radius: 20px;
}

.onematchodds::after {
  display: none;
}

.match-odd-header-tie {
  position: relative;
}
.match-odd-header-text {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 0;
  white-space: nowrap;
  gap: 3px;
  margin-right: 1.0714285714285714rem !important;
}
.match-odd-header-text svg {
  margin: 4px 0 0 !important;
}
.match-odd-header-text span {
  width: auto !important;
}
.match-odd-header-tie::after {
  content: "";
  position: absolute;
  width: 65px;
  height: 3px;
  background: #000;
  bottom: 4px;
  left: 16px;
}
.slick-track {
  margin-left: 0 !important;
}

.fancy-sport-header button.active-taber:hover {
  background: #ffc800 !important;
}

.fancy-sport-header button.active-taber:hover {
  background: #ffc800 !important;
}

.sports-widget {
  background: #000;
  color: #fff;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.sports-widget ul {
  background: #fff;
  color: #000;
  padding: 7px 9px;
  border-radius: 6px;
  border: 2px solid transparent;
  cursor: pointer;
}

.home-banner-sec .slick-slide > div {
  margin: 0px 0px;
  line-height: 0;
}
.slick-slide > div {
  margin: 0px 4px;
}

.sports-widget ul li {
  display: flex;
  justify-content: space-between;

  font-weight: 600;
  font-size: 13px;
}

.sports-widget ul li span {
  display: block;
  color: #c69700;
}
.sports-widget ul.widget-active span {
  /* color: #000; */
}
.sports-widget ul.widget-active {
  outline-style: solid;
  outline-width: 1px;
  outline-color: #ffc800;
  box-shadow: inset 0 0 3px 1px #0006;
  /* background-color: #ffc800; */
  border: 2px solid #ffc800;
}
.widgetCloseButton {
  opacity: 1;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: absolute;
  right: 0;
  width: 4rem;
  top: 0;
  bottom: 0;
  cursor: pointer;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 33px;
}
/* 7/6/23 */

/* 8/6/23 */

.back-lay-head {
  width: 146px;

  font-size: 13px;
}

.bookmaker .accordion-header button {
  background: #ffc800;
  color: #000;
  padding-left: 3px;
  padding: 8px 19px 8px 1px;
  border-radius: 0px;
  box-shadow: none;
  border: none;
  font-size: 1rem;
}

.bookmaker .accordion-item {
  border-radius: 10px !important;
  overflow: hidden;
  border: none;
}
.header {
  padding: 6px 8px;
}

.info-detail {
  position: absolute;
  top: 0;
  background: #fff;
  bottom: 0;
  z-index: 11;
  left: 0;
  right: 0;
}

.info-detail .title {
  background: #000;
  color: #fff;
  padding: 14px 0px;
  text-align: center;
}

.info-detail .title h4 {
  font-size: 19px;
  margin: 0;
}

.info-detail .info-body {
  padding: 10px;
  height: calc(100% - 42px);
  overflow-y: auto;
}

.info-rule li {
  font-size: 17px;
  margin-bottom: 22px;
  padding: 0px 36px 0px 4px;
}

.info-rule {
  padding-left: 56px;
}

.sport-rules-sec li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 13px 12px 13px 52px;
  border-bottom: 1px solid #00000021;
  align-items: center;
  font-size: 20px;
  font-family: "Ubuntu";
  background-image: url(../images/pdf-icon.png);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: top 22px left 11px;
}
.team-red-score {
  font-size: 0.7142857142857143rem;
  font-family: "Lato";
  color: #e20000;
  font-weight: 700;
}
.team-green-score {
  font-size: 12px;
  font-family: "Lato";
  color: green;
  font-weight: 700;
}
.text-green {
  color: green !important;
}
.sport-rules-sec li button {
  background: #ffc800;
  border: none;
  color: #000;
  font-size: 21px;
}
.sport-rules-sec li button:hover,
.sport-rules-sec li button:active {
  background: #ffc800 !important;
}

/* 8/6/23 */

/* 9/6/23 */

.search-games-sec {
  position: absolute;
  background: aliceblue;
  z-index: 10;
  bottom: 0;
  top: 166px;
  left: 0;
  right: 0;
  padding: 10px;
  overflow: hidden;
}

.search-games-sec .form-control {
  border: 0;
  border-bottom: 1px solid #e3e0e0bd;
  border-radius: 0px;
  background: transparent;
  padding-bottom: 14px;
  font-family: "Ubuntu";
}

.search-games-sec .form-control:focus {
  box-shadow: none;
  background: transparent;
  border: none;
}
.match-special-word {
  color: #198ee3;
}
.search-listing {
  height: calc(100vh - 223px);
  overflow-y: auto;
}

.footer-bets.plays-bet {
  position: fixed;
  bottom: 0;
  background: #fff;
  min-height: auto;
  width: 100%;
  border-radius: 0.75rem 0.75rem 0px 0px;
  box-shadow: 0 -0.2rem 3rem 0 #000;
  padding: 7px;
  z-index: 1000;
  left: 0;
}

.footer-bets.plays-bet button.bg-yellow {
  padding: 4px 6px;
  font-size: 15.38px !important;
  line-height: 24px;
}

.footer-bets.plays-bet .fw-bold {
  font-size: 1.2857142857142858rem;
}

.add-bet {
  background: #eef6fb;
  padding: 6px 1px;
}

.add-bet button {
  color: #000;
  background: #ffc800;
  border: none;
  font-size: 25px;
  width: 33.59px !important;
  height: 33.59px !important;
  display: flex;
  justify-content: center;
  margin: 0px 4px;
  border-radius: 4px;
  line-height: 18px;
}

.add-bet .input-form-control {
  width: calc(100% - 64px);
  padding: 3px 8px;
  border-radius: 4px;
  border: 1px solid black;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.add-bet span {
  color: #74828b;
  font-family: "Ubuntu";
  margin-bottom: 8px;
  font-size: 0.7857142857142857rem;
}

.add-bet button:hover,
.add-bet button:active {
  background: #ffc800 !important;
  color: #000 !important;
}

/* .add-bet .input-form-control:focus {
  box-shadow: none;
  border: 2px solid #e7bb6f;
} */
.input-form-control:focus-visible {
  border: 2px solid #e7bb6f !important;
  /* box-shadow: none; */
}

.fix-bet-btn button {
  width: calc(25% - 4px);
  margin-right: 4px;
  font-weight: 700;
  font-size: 14px;
  padding: 7px 7px;
  font-size: 13.44px !important;
  text-align: center;
}

/* 9/6/23 */

/* 12/6/23 */

.bet-numeric button {
  width: calc(16.66% - 2px) !important;
  margin: 1px;
  padding: 10px 14px;
  font-size: 13.4px !important;
}

.betting-numbers {
  margin-top: 5px;
}

.betting-numbers button {
  background: #eef6fb;
  border-color: #eef6fb;
  color: #000;
  font-size: 17px;
}
.betting-numbers button:hover,
.betting-numbers button:active {
  background: #eef6fb !important;
  border-color: #eef6fb !important;
  color: #000 !important;
}
.cross-bet {
  height: 80px !important;
  width: 80px !important;
}
.cross-bet img {
  max-width: 25px !important;
}

.is-outside {
  width: 100%;
  height: auto;
  margin-right: 0;
  position: fixed;
  right: 0.6428571429rem;
  top: 3.8571428571rem;
  transform: scale(0.5);
  transform-origin: 100% 0;
  width: 100%;
  z-index: 99;
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#videoFrame {
  line-height: 0;
}
.is-outsite-icon {
  position: absolute;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  padding-left: 9px;
  padding-top: 9px;
  right: 10px;
  top: 10px;
}
.is-outsite-icon-mute {
  position: absolute;
  z-index: 9;
  background: rgba(0, 0, 0, 0.6);
  border-radius:1.6vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  left: 5%;
  bottom: 10%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 8px 14px;
  width: 19%;
}
.is-outsite-icon-mute span {
  font-size: 7px;
  margin-left: 4px;
  color: white;
}
.wifi-icon {
  position: relative;
}
.wifi-icon span {
  position: absolute;
  color: #fff;
  bottom: -20%;
  left: 90%;
  font-size: 13px;
}
.cross-bet button {
  width: 100%;
}
/* 12/6/23 */

/* 13/6/23 */

.market-depth-modal {
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 99999999;
  width: 100%;
  /* top: 0; */
  left: 0;
  right: 0;
  /* box-shadow: 10px 10px 10px 10px; */
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s ease;
  will-change: top;
  height: calc(100vh - 5.85714rem);
  border: 0.25rem solid #000;
}

.market-depth-modal-casino {
  position: fixed;
  bottom: 0;
  background: #000;
  z-index: 99999999;
  width: 100%;
  top: 120%;
  left: 0;
  right: 0;
  box-shadow: 10px 10px 10px 10px;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  padding: 0 0.25rem;
  transition: all 0.3s ease;
  will-change: top;
}
.market-depth-modal-footer {
  position: absolute !important;
  top: 0;
}
.active-model {
  top: 0 !important;
}
.market-depth-modal-announcement {
  border-radius: 0% !important;
  background-color: #fff;
}
.market-depth-body {
  overflow-y: auto !important;
  background: #fff;
  height: 100%;
  border-radius: 12px 12px 0 0;
}
.announcement-main {
  display: flex;
  flex-direction: column;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 1rem;
}
.announcement-main-time {
  margin-bottom: 1rem;
  font-weight: 700;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  font-size: 0.8571428571428571rem;
  border-radius: 0.75rem;
}
.announcement-main-content {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  word-break: break-all;
}
.announcement-main-inner {
  border-bottom: 1px solid rgb(212 224 229/1);
  margin-bottom: 12px !important;
}
.market-title {
  color: #fff;
  background: #000;
  text-align: center;
  padding: 13px 6px;
  /* font-size: 5px; */
}
.market-title-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.market-depth-body .match-point {
  background: #d4e0e5;
  padding: 10px 14px;
  text-align: center;
  border-radius: 12px 12px 0 0;
  height: 2.857142857142857rem;
}
.market-depth-body table td {
  border: 1px solid #d4e0e5;
  padding: 0.5rem;
  line-height: 18px;
  font-size: 0.7142857142857143rem;
  text-align: left !important;
  color: #1e1e1e;
}
.market-depth-body table td strong {
  font-size: 15.38px;
}
.match-point .master-pass:first-child::before {
  display: none;
}

.to-back-to-lay table {
  width: 588px;
  text-align: center;
  margin: 0;
}

.to-back-to-lay {
  overflow-x: auto;
}
.to-back-to-lay th {
  border-right: 1px solid #0000001f;
  border-bottom: 1px solid #0000004d;
}
.bet-notification > div {
  max-width: 80px;
  padding-left: 13px;
  position: relative;
}
.bet-notification {
  position: fixed;
  bottom: 100px;
  z-index: 3;
}
.market-depth-body ol {
  max-height: 400px;
  overflow-y: auto;
}
.bet-notification > div span {
  position: absolute;
  background: red;
  width: 19px;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 19px;
  font-size: 10px;

  position: absolute;
  top: 12px;
  right: 11px;
  font-weight: 600;
}

.market-depth-body li {
  padding: 10px;
  border-bottom: 1px solid #0000001f;
}

.market-depth-body li {
  padding: 13px 18px;
  border-bottom: 1px solid #0000001f;
}
.right-angel {
  background-color: #d4e0e5 !important;
  border-color: #d4e0e5 !important;
  color: #000 !important;
}

/* 13/6/23 */

/* 14/6/23 */

.bg-skylight {
  background-color: aliceblue !important;
}
.bg-darklight {
  background-color: #d4e0e5;
}

.bg-skylight.px-1 {
  padding: 0 !important;
}

.bg-skylight.px-1 .d-flex.justify-content-between.align-items-center {
  background: linear-gradient(-180deg, #d4e0e5, #eef6fb);
  padding: 0.3rem 0.7rem;
}

.match-border-sec {
  width: max-content;
  margin: 0 auto;
  border-bottom: 4px solid #000;
  padding-bottom: 6px;
}
.single-match-body {
  height: calc(100vh - 290px);
  overflow-y: auto;
  background: #e2eaef;
  margin: 8px;
  border-radius: 0;
}
.default-stake {
  min-height: 442px;
  /* height: 95%; */
}
.default-stake .betting-numbers button {
  width: calc(33.33% - 4px);
  min-height: 53px;
  height: 100%;
}
.default-stake .form-control {
  padding: 10px;
}
.default-stake .form-control {
  padding: 10px;
}

.leading-normal {
  background: #ffffff91;
  width: max-content;
  margin: 0 auto;
  /* padding: 2px 11px; */
  border-radius: 19px;
}

.leading-normal div {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.sport-accordian .accordion-header button {
  background: #000;
  color: #ffc800;
  padding-left: 22px;
  font-weight: 400;
  font-family: "Ubuntu";
  padding-right: 0.8571428571428571rem;
  padding-left: 0.5rem;
  padding-bottom: 0.65rem;
  padding-top: 0.65rem;
}
.sport-accordian .accordion .accordion-header .accordion-button::after {
  content: none;
  /* background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23bc4329'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important; */
}
.sport-accordian .accordion .accordion-header .accordion-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.sport-accordian {
  padding: 4px 0.35rem !important;
  padding-top: 0 !important;
}
.sport-accordian .accordion-header button::after {
  filter: brightness(0) invert(1);
}

.sport-accordian .accordion-header button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}
.counter {
  color: #c69700;
  font-weight: 700;
}
.icon-sports {
  position: relative;
  margin-left: 0px;
}
.icon-sports div {
  display: flex;
  align-items: center;
}
.in-play {
  background: #52bf05;
  color: #fff;
  padding: 0px 6px;
  font-weight: 600;
  line-height: 12px;
  font-size: 0.5714285714285714rem;
}
.in-play.ms-1 {
  margin-left: 2px !important;
  line-height: 3px;
  padding: 2px 0.21428571428571427rem;
  font-weight: 700;
  font-size: 0.5714285714285714rem;
  height: 9.88px;
}
.gap12 {
  gap: 12px;
}
.sport-accord-body {
  padding: 0.42857142857142855rem 0px;

  border-bottom: 1px solid rgb(238 246 251/1);
}
.sport-accord-body .ps-4 {
  font-size: 0.9285714286rem;
}

img.spotrtssaba_home {
  border-radius: 0.75rem;
  margin-bottom: 0.37rem;
}

.betbtn1 {
  bottom: 50px;
  right: 65px;
  z-index: 99;
}
.betbtn1 img {
  background-size: contain !important;
  width: 72.54px;
  height: 72.54px;
}
.betbtn1.betSlipBtn .apana-icon {
  width: 20px;
  height: 20px;
}
.betSlipBtn {
  top: auto;
  background-color: #ffc800;
  bottom: 6rem !important;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.betSlipBtn span {
  position: absolute;
  top: 10%;
  font-size: 8px;
  left: 60%;
  background-color: red;
  color: white;
  padding: 1px 4px;
  border-radius: 50%;
}
.betbtn2 {
  align-items: center;
  background: #1b1f23;
  border-top-left-radius: 5px;
  border-radius: 50%;
  bottom: 57px;
  cursor: vertical-text;
  display: grid;
  height: 3.4285714285714284rem;
  right: 10px;
  text-align: center;
  text-transform: 1px 1px 0 #00000080;
  text-transform: capitalize;
  width: 3.4285714285714284rem;
  z-index: 10;
  z-index: 9999;
}
.betbtn2 svg {
  background-size: contain !important;
  width: 26px;
  height: 26px;


}
.text-24 {
  font-size: 1.7142857142857142rem;
}

.bounce-4 {
  animation-name: bounce-4;
  animation-timing-function: ease;
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
}
@keyframes bounce-4 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-51px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
.today {
  background-color: #ffd84d;
  color: #000;
}
.tommorow {
  background-color: lightgrey;
  color: #000;
}
.sport-accord-body > div {
  padding: 0px 0px;
  border-bottom: 1px solid #00000017;
}
.sport-accord-body > div:last-child {
  border: none;
}
.sport-counter {
  padding: 2px 0.7rem;
  margin-right: 6px;
  border-radius: 21px;
  font-size: 0.7857142857142857rem;
  font-weight: 800;
}
.sports-heading {
  font-weight: 600;
  font-family: "Ubuntu";
  font-size: 18px;
  position: relative;
  border-left: 3px solid #000;
  padding-left: 7px;
  margin-top: 5px;
}

.all-complete {
  background: #eef6fb;
  padding: 0.6rem 0.5rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.all-complete-all {
  background: #fff;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 0.7857142857142857rem;
  padding: 2px 7px 2px 7px;
  font-weight: normal;
  cursor: pointer;
  /* padding-left: 7px; */
}
.all-complete .dropdown-menu {
  transform: translate(0px, 26px) !important;
}
.all-complete button {
  background: #fff;
  border: none;
  color: #000;
  font-size: 0.7857142857rem;
  width: 100%;
  font-weight: normal;
  text-align: left;
  padding-left: 0.42857142857142855rem;
  padding-right: 0.42857142857142855rem;
}
/* .all-complete button:hover{
  background-color: transparent!important;
  color: #000!important;
} */
.all-complete button:hover {
  background-color: #fff;
  color: #000;
}
.all-complete .dropdown-menu a {
  color: #000;
  font-size: 0.7857142857142857rem;
  font-weight: normal;
  padding: 5px 20px;
}
.all-complete .dropdown-menu a:hover {
  background-color: transparent;
}
.all-complete .dropdown-menu {
  background-color: #fff;
  border: none;
  padding: 0px;
  width: 100%;
}

/* .all-complete .dropdown-toggle::after {
  filter: brightness(0);
  background-size: 22px;
  background-position: left 6px top 4px;
  position: absolute;
  right: 9px;
  top: 45%;
  display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .4em solid !important;
    border-right: 0.4em solid transparent !important;
    border-bottom: 0;
    border-left: .4em solid transparent !important;
} */

.sports-team-table .overlay-match {
  width: 31% !important;
}

.sports-team-table .back-lay-head {
  padding: 7px 0px;
  border-radius: 8px 8px 0px 0px;
}

.sports-team-table .back-lay-head h4 {
  font-size: 12px;
}

.sports-team-table .back-lay-head .half-table {
  /* flex: 0 0 220px; */
  align-items: center;
  font-family: "Ubuntu";
  padding-left: 8px;
  color: #00000094;
  border-right: 1px solid #00000078;
}

.sports-team-table .back-lay-head h4 {
  margin: 0;
  font-size: 0.9285714286rem;
  font-weight: 700;
}

.sports-team-table .back-lay-head .half-table span {
  margin: 0px 8px;
}
.sports-team-table {
  padding: 5px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.half-table span {
  padding-right: 0.1rem;
  font-size: 0.7142857143rem;
  margin: 0 !important;
}

.half-table strong {
  /* padding-right: 0.1rem; */
  font-size: 0.7142857143rem;
  margin: 0 !important;
}

.icon-sports img {
  height: 12px;
  margin-right: 1px;
}
/* 14/6/23 */

/* 19/6/23 */

.parlay-tab {
  background: #000;
  color: #fff;
  font-family: "Ubuntu";
}

.parlay-inner-tab li {
  display: inline-block;
  margin-right: 9px;
  color: #fff;
  opacity: 0.75;
}
.parlay-inner-tab li.active {
  color: #fff;
  opacity: 1;
  font-weight: 800;
  z-index: 0;
}
.parlay-checkbox .parly-check:checked {
  background-color: red !important;
  color: red !important;
}

/* .parlay-inner-tab li.active {
  border-bottom: 3px solid #fff;
} */
.parlay-checkbox {
  background: #1b1f23;
  padding: 8px 5px;
  margin-right: 13px;
  color: #6f8898;
  font-size: 1.0714285714285714rem !important;
}
.parlay-checkbox span {
  font-size: 1.0714285714285714rem !important;
}
.parlay-icons svg {
  font-size: 27px;
  margin-right: 12px;
  cursor: pointer;
}
.parlay-checkbox .form-check-label {
  font-weight: 700;
}
.bet-history-dropdown .dropdown-toggle:active,
.bet-history-dropdown .dropdown-toggle:hover,
.bet-history-dropdown .btn.show:active {
  background: transparent !important;
}

.parlay-checkbox .form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1.75rem !important;
  height: 0.875rem;
  margin-top: 0.25em;
  vertical-align: text-top;
  appearance: none;
  background-color: #6f8898;
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none !important;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

/* 19/6/23 */

/* 23/6/23 */
.games-slot .slick-dots li button:before {
  color: #ffefef;
  line-height: 4px !important;
  width: auto;
  height: auto;
  right: 0;
}
.games-slot .slick-dots li {
  margin: 0 !important;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ffc800;
  width: 1.4285714285714286rem;
  font-size: 0;
  background: #ffc800;
  height: 3.82812px;
  border-radius: 0.25rem;
  margin-bottom: 0.07142857142857142rem;
  opacity: 1 !important;
}
.slick-dots li button:before {
  color: #ffefef;
}

.all-complete .btn-check:checked + .btn,
.all-complete .btn.active,
.all-complete .btn.show,
.all-complete .btn:first-child:active,
.all-complete button:not(.btn-check) + .btn:active {
  background-color: #fff;
  color: #000;
}
.all-complete .dropdown {
  /* max-width: 161px; */
  width: 8.14285714286rem;
}

.by-time-sec li {
  display: flex;
  align-items: center;
}

.by-time-sec li .in-play {
  height: 100%;
  display: block;
  padding: 13px 11px;
  font-size: 15px;
}

/* .by-time-sec {
  margin: 0px -8px;
} */

.by-time-sec .capital {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.by-time-sec .capital .matched {
  color: #00000078;
}

.capital-inner {
  flex: 0 0 229px;
}

.capital .counter {
  flex: 1 1;
  text-align: right;
  max-width: 100%;
}
.by-time-sec .row {
  border-bottom: 1px solid #00000021;
}

.live-score {
  background: #1e1e1e;
  color: #fff;
  padding: 7px 68px;
  text-align: center;
  display: flex;
  font-size: 19px;
  font-weight: 500;
  font-family: "Ubuntu";
  justify-content: space-between;
}
.live-score span {
  width: max-content;
  display: block;
  cursor: pointer;
}
.team-score .minus {
  display: block;
  width: 115.33%;
}

.team-score span {
  font-size: 19px;
  font-weight: 700;
}
.parlay-acc .accordion-item {
  margin-bottom: 13px;
  background: #fff;
}
.parlay-acc .accordion-button:not(.collapsed),
.parlay-acc .accordion-button {
  background-color: #ffffff;
}
.parlay-acc .team-wise-score {
  background: #d4e0e5;
  border-radius: 6px;
  border: 1px solid transparent;
}
.parlay-acc .accordion {
  padding: 8px;
  background: aliceblue;
}
.live-score span.active {
  border-bottom: 3px solid #fff;
}
/* 23/6/23 */

/* 26/6/23 */

.header .social-header a figure {
  max-width: 20px;
  margin: 0;
  margin-right: 5px;
}

.after-login strong {
  font-size: 14px;
  color: #00000063;
  margin-right: 8px;
  font-family: "Ubuntu";
}

.after-login strong span {
  font-weight: normal;
  color: #000;
}
.after-login svg {
  font-size: 26px;
}
.parlay-acc .team-wise-score:visited {
  background: transparent;
  border-color: #000;
}

/* 26/6/23 */

/* 27/6/23 */

.logout-button {
  background: #d4e0e5;
  border: none;
  color: #000;
  width: 100%;
  text-align: left;
  font-size: 19px;
  font-family: "Ubuntu";
  padding: 10px 22px;
  border-radius: 10px;
  background-image: url(../images/next.png);
  background-repeat: no-repeat;
  background-position: right 11px top 13px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.time-zone {
  color: #969ea1;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
}
.whatsapp-area {
  padding: 7px 10px 7px 15px;
  border-radius: 8px;
  border-bottom: 1px solid #00000026;
}

.whatsapp-area a {
  text-decoration: none;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.whatsapp-area span {
  padding: 8px 14px;
  border-radius: 7px;
}
.login-log-sec {
  bottom: 0;
  position: fixed;
  left: 0;
  max-width: 480px;
  margin: auto;
  left: 0;
  right: 0;
  width: 100%;
}

.login-log-sec button.theme-btn.ms-auto.px-5.btn.btn-primary {
  width: 160px;
  height: 36px;
  font-size: 1rem;
  margin-bottom: 10px;
  border-radius: 0.25rem;
}

.login-log-sec form.bg-white.p-4 {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding: 1.2rem 8% !important;
}

.closed-bet-slip li {
  background: #dee6ea;
  padding: 6px 6px;
  border-radius: 5px;
}

.closed-bet-slip li strong {
  background: #6f8898;
  color: #fff;
  padding: 5px 12px;
  border-radius: 5px;
}

.closed-bet-slip li .closed {
  color: #0000006e;
}

.closed-bet-slip li svg {
  color: #6f8898;
}
.closed-bet-slip {
  max-height: 323px;
  overflow-y: auto;
}

.closed-bet-slip li:last-child {
  margin-bottom: 0px !important;
}
/* 27/6/23 */

/* 28/6/23 */

.form-stake h4 {
  font-size: 18px;
  font-weight: 600;
}

.form-stake h4 strong {
  color: #c69700;
  font-weight: 700;
  font-size: 17px;
}

.form-stake span {
  font-size: 14px;
  padding-left: 10px;
  color: #52bf05;
  font-weight: 800;
}

.form-stake .form-control {
  width: 102px;
  margin-left: 10px;
  text-align: center;
  font-weight: 600;
}
.total-stack {
  border-top: 1px solid #00000036;
  border-bottom: 1px solid #00000024;
  padding: 13px 0px;
}

.total-stack button {
  flex: 0 0 230px;
  max-width: 230px;
  padding: 20px 10px;
  font-size: 20px;
  font-weight: 600;
}
.total-stack button.disabled {
  background: #b7c6cd !important;
  color: #1e1e1e !important;
}
.total-stack h5 {
  font-size: 23px;
}

.total-stack h6 {
  font-size: 19px;
}
.accept-odds span {
  background: #00000021;
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
}
.plays-bet form {
  border-top: 1px solid #0000001f;
  /* margin-top: 16px; */
}
/* 28/6/23 */

.in-play-green {
  font-size: 15px;
}

.by-time-sec .sports-heading {
  border-color: #fff;
  color: #ffc800;
  margin: 0px -13px;
  padding: 8px 10px;
  padding-left: 25px !important;
  position: relative;
}

.by-time-sec .sports-heading::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 55%;
  background: #ffc800;
  left: 14px;
  border-radius: 3px;
}

.master-pass.first::before {
  content: "";
  border: none;
}

.master-pass.first {
  padding-left: 0px;
}
.topnav {
  /* margin-top: 10px; */
  background-color: rgb(30 30 30);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
  margin: 0;
  padding: 0;
  /* height: 50px; */
}

.topnav p {
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 1px 2px;
  text-decoration: none;
  font-size: 0.9285714285714286rem;
  margin-bottom: 0;
  position: relative;
}

/* .topnav p:hover {
  border-bottom: 2px solid white;
} */
.topnav .active {
  font-weight: 700;
}
.topnav .active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2142857143rem;
  background: rgb(255 255 255/1);
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 9999px;
  /* transform: translateX(0%); */
  /* transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}

/* .topnav p.active {
  border-bottom: 4px solid rgb(255 255 255/1);
  font-weight: 800;
} */
.live-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  height: 2.857142857142857rem;
  padding: 0 10px;
  background: rgb(255 255 255/1);
}
.live-header .score_color {
  color: #c69700 !important;
}
.keyboard-div :disabled {
  background-color: #d4e0e5 !important;
  color: #000 !important;
}
.text-red {
  color: red !important;
}
.text-green {
  color: green !important;
}
.form-control:disabled {
  opacity: 0.6 !important;
}
.book-button {
  margin-left: 10px;
  border-radius: 8px;
  background-color: #000;
  color: white;
  font-size: 15px;
  padding: 2px 8px;
}
.book-button .icon {
  margin-right: 4px;
  border: 1px solid white !important;
  background-color: white !important;
  color: black;
  border-radius: 10px;
}
.book-position-title {
  background-color: white;
  padding: 5px;
  color: #000;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.book-position-title .first-span {
  font-weight: 700;
  margin-right: 5px;
}
.runs-book-position {
  display: flex;
  align-items: start;
  background-color: #000;
  color: white;
  padding: 4px 12px;
}
.message {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 7px;
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 999999999999 !important;
  width: 100%;
  left: 0;
  right: 0;
  box-shadow: 10px 10px 10px 10px;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
}
.inner-messageBox {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  background-color: rgb(255 255 255 0.87) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-messageBox p {
  font-size: 14px;
  text-align: center;
  padding: 2px;

  white-space: break-spaces;
}
.error {
  background-color: #e20000 !important;
}
/* .message .market-title {
  color: #fff;
  background: #000;
  text-align: center;
  padding: 13px 6px;
} */

.market-title h6 {
  font-size: 0.9rem;
  margin: 0;
  color: #ffc800;
  font-weight: 700;
}

.market-title h4 {
  font-size: 0.9285714285714286rem;
  margin: 0;
  color: black;
  font-weight: 700;
}
.error .market-title {
  background-color: #e20000 !important;
}
.error .messageBox {
  background-color: #e20000 !important;
}
.success {
  background-color: #62b70b !important;
}
.success .market-title {
  background-color: #62b70b !important;
}
.success .messageBox {
  background-color: #62b70b !important;
}
.placed-bet {
  display: flex;
  align-items: center;
  font-weight: 500 !important;
  padding: 6px 2px;
  background-color: #e9f3df !important;
}
.placed-bet .lay {
  background-color: #faa9ba;
  padding: 4px 10px;
  font-weight: 700;
  border-radius: 8px;
}
.placed-bet .back {
  position: relative;
  background-color: #7cc3f5;
  padding: 4px 10px;
  font-weight: 700;
  border-radius: 8px;
}
.placed-bet span {
  margin-right: 8px;
}
.placed-bet .line {
  border-right: 0.5px solid grey;
  padding-right: 5px;
}

/* .lay-back-table .disabled{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .5;
  z-index: 99;
  cursor: pointer;
} */

/* Spark */

.spark-back {
  animation: sparkBack 0.6s ease;
}

@keyframes sparkBack {
  0% {
    background-color: #72bbef;
    color: #1e1e1e;
  }

  50% {
    background-color: #f8e71c;
    color: #fff;
  }

  100% {
    background-color: #72bbef;
    color: #1e1e1e;
  }
}
.spark-lay {
  animation: sparkLay 0.6s ease;
}

@keyframes sparkLay {
  0% {
    background-color: #faa9ba;
    color: #1e1e1e;
  }

  50% {
    background-color: #26f1f8;
    color: #fff;
  }

  100% {
    background-color: #faa9ba;
    color: #1e1e1e;
  }
}
.loading-bet {
  position: absolute;
  z-index: 10;
  /* min-height: 434px; */
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(38 44 50/0.8);
  border-radius: 0.75rem 0.75rem 0px 0px;
  /* box-shadow: 10px 10px 10px 10px #000; */
}
.back-active {
  background-color: rgb(55 157 230/1) !important;
}
.lay-active {
  background-color: rgb(246 105 135/1) !important;
}

.back {
  position: relative;
}
.lay {
  position: relative;
}

.back .single-disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(38 44 50/0.6);
  border-radius: 6px;
  width: 100%;
  height: 100%;
}
.lay .single-disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(38 44 50/0.6);
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

/* .without-login {
  padding-top: 45px !important;
} */
.with-login {
  padding-top: 40px !important;
}
.sports-listing-score .sports-book-disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(111 136 152/0.6);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.sports-book-disabled .sports-book-disabled-text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.7857142857142857rem;
  font-weight: lighter;
  z-index: 9;
  color: #fff;
}

.profit-loss-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: rgb(156 177 189/1);
  font-size: 13px;
}
.profit-loss-div-inner {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
  background-color: #e2eaef;
  align-items: center;
  font-size: 0.9285714285714286rem;
  font-weight: 700;
  border-bottom: 2px solid rgb(156 177 189/1);
}
.profit-loss-div-inner-tabel tbody td {
  font-size: 0.7142857142857143rem;
}
.profit-loss-div-inner-tabel tbody tr td {
  background-color: #e2eaef !important;
  border-right: 1px solid rgb(156 177 189/1);
}
.profit-loss-div-inner-tabel tbody tr td:last-child {
  border-right: none !important;
}
.profit-loss-div-inner-tabel tbody tr {
  border-top: 1px solid rgb(156 177 189/1);
}
.toss-back {
  width: 35%;
}
.toss-back .back {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.arrow-icon {
  margin: 0 0.2857142857rem;
  font-size: 13px;
  color: #5a5e62;
}

.bets-table tbody tr td {
  background-color: rgb(226 234 239/1);
  border-right: 1px solid rgb(156 177 189/1);
}
.bets-table tbody tr {
  border-bottom: 1px solid rgb(156 177 189/1);
}
.bets-table tbody tr:last-child {
  border-width: 0;
}

.bets-table tbody tr td:last-child {
  border-right: none !important;
}
/* .back .disabled{
 background-color: rgb(38 44 50/.4);
  position: absolute;
  top: 0%;
  left: 59%;
  z-index: 99;
  border-radius: 6px;
  width: 72px;
  height: 47px;
 }
 .lay .disabled{
  background-color: rgb(38 44 50/.4);
   position: absolute;
   top: 0%;
   left: 80%;
   z-index: 99;
   border-radius: 6px;
   width: 72px;
   height: 47px;
  } */

.profit-loss-footer {
  background: rgb(226 234 239/1);
}
.profit-loss-footer-inner {
  padding: 5px;
  border-bottom: 1px solid rgb(156 177 189/1);
}
.profit-loss-footer-inner > div {
  font-size: 0.8571428571428571rem !important;
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profit-loss-footer-inner-footer {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  font-size: 0.8571428571428571rem !important;
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pl-filter {
  background-color: rgb(38 44 50/1);
}
.pl-filter-first {
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pl-filter-first-time {
  display: flex;
  width: 65%;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid #ffc800;
  border-radius: 5px;
  padding: 3px;
}
.pl-filter-first-time svg {
  color: #ffc800;
  font-size: 20px;
}
.pl-filter-first button {
  border: none;
  font-weight: 700;
  background-color: #ffc800;
  color: black;
}
.pl-filter-first button:hover {
  border: none;
  font-weight: 700;
  background-color: #ffc800;
  color: black;
}
.pl-filter-first button::selection {
  border: none;
  font-weight: 700;
  background-color: #ffc800;
  color: black;
}

.calendar-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.calendar-button button {
  border: none;
  font-weight: 700;
  background-color: #ffc800;
  color: black;
}
.calendar-button button:hover {
  border: none;
  font-weight: 700;
  background-color: #ffc800;
  color: black;
}

.refresh-icon {
  animation: spin 1s linear infinite;
  transform: rotate(0);
}
@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
  50% {
    transform: rotate(180deg);
  }
}

.loader-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}
.loader-inner-icon {
  background-color: #1b1f23;
  border-radius: 9999px;
  animation: loadBar 0.8s ease infinite;
  width: 0.2857142857142857rem;
  height: 0.2857142857142857rem;
  margin-right: 0.5rem;
}
@keyframes loadBar {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
    transform: scale(1.4);
  }
  100% {
    opacity: 0.1;
  }
}

.loading {
  background-color: #262c32b3;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
}
.loading-box {
  background: rgba(27, 31, 35, 0.7);
  border: 1px solid #8b6b05;
  width: 7rem;
  border-radius: 0.5rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-spinner {
  height: 1.4285714286rem;
  position: relative;
  width: 50%;
}
.loading-spinner::before,
.loading-spinner::after {
  background-color: #ffc800;
  content: "";
  height: 1.4285714286rem;
  position: absolute;
  width: 1.4285714286rem;
}

.loading-spinner::before {
  animation: spinnerLeftRight 0.6s 95ms infinite;
  border-radius: 50%;
  left: -10%;
  opacity: 1;
}
.loading-spinner::after {
  animation: spinnerLeftRight 0.6s infinite;
  border-radius: 100%;
  left: -15%;
  opacity: 0.4;
}
@keyframes spinnerLeftRight {
  0% {
    left: 75%;
  }

  50% {
    left: -15%;
  }

  to {
    left: 75%;
  }
}

.no-event {
  line-height: 1.25;
  font-size: 1rem;
  text-align: center;
  padding-bottom: 1.25rem;
  padding-top: 1.5rem;
  background-color: rgb(255 255 255/1);
  border-radius: 0.75rem;
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: center;
}
.no-event-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 380px;
  width: auto;
}
.match-odd-header-outer {
  height: 2.4285714285714284rem;
  padding-left: 10px;
}

.match-odd-header-outer {
  font-size: 17px;
}
.match-odd-header-outer-soccer {
  height: 2.4285714285714284rem !important;
  padding-left: 10px;
  padding-bottom: 1px;
  overflow-x: scroll;
}

/* .match-odd-header-outer-soccer > div {
 width: 400px !important;
} */

.search-input-outer {
  background: #000;
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-input {
  padding: 10px 20px;
  background: #000;
  border: none;
  outline: none;
  width: 100%;
  color: #fff;
}
.sports-widget-ul li .text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 54%;
  font-size: 11px;
  white-space: nowrap;
  color: #000;
}

.tv-outer {
  position: relative;
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: scale(1);
  transform-origin: 100% 0;
}
.place-bet-button:disabled {
  background-color: #d4e0e5 !important;
}
/* .place-bet-button{
  height: 40px !important;
  padding: 4px !important;
} */
.bg-bookmaker-market > div > span > strong {
  font-size: 14px !important;
}

.draggable-component {
  width: 100px;
  height: 100px;
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dragging {
  cursor: grabbing;
  opacity: 0.8;
}

.icon-parlay:before {
  content: "";
}

.icon-parlay-list:before {
  content: "";
}

.icon-parlay-up:before {
  content: "";
}
.betbtn1.betSlipBtn .apana-icon {
  width: 20px;
  height: 20px;
}
.betSlipBtn {
  top: auto;
  background-color: #ffc800;
  bottom: 6rem !important;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.betSlipBtn span {
  position: absolute;
  top: 10%;
  font-size: 8px;
  left: 60%;
  background-color: red;
  color: white;
  padding: 1px 4px;
  border-radius: 50%;
}
.bg-black-100 {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.bg-black-300 {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  /* border-radius: 0.5rem; */
  --tw-bg-opacity: 1;
  background-color: rgb(212 224 229 / var(--tw-bg-opacity));
}
.text-black-300 {
  --tw-text-opacity: 1;
  color: rgb(212 224 229 / var(--tw-text-opacity));
}
.bg-black-600 {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(111 136 152 / var(--tw-bg-opacity));
}
.text-black-400 {
  --tw-text-opacity: 1;
  color: rgb(156 177 189 / var(--tw-text-opacity));
  padding-bottom: 0.2857142857142857rem;
  padding-top: 0.2857142857142857rem;
}
.icon-triangle-black-400 {
  border-color: transparent transparent transparent #9cb1bd;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  height: 0;
  margin: 0 0.2857142857rem;
  width: 0;
  font-size: 0.5714285714285714rem;
}
.text-black-600 {
  font-size: 1.2857142857142858rem;
  --tw-text-opacity: 1;
  color: rgb(111 136 152 / var(--tw-text-opacity));
}
.flex {
  display: flex;
}
.bg-button-normal {
  background-color: var(--color-button-normal);
}
.disabled\:bg-black-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(212 224 229 / var(--tw-bg-opacity));
}
.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}
.rounded-md {
  border-radius: 0.375rem;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.active-sportBook {
  border: 1px solid black;
  background-color: rgb(238, 246, 251);
}
.active-sportBook-parly {
  border: 1px solid black !important;
  background-color: rgb(238 246 251/1) !important;
}
.newPlaceButton:disabled {
  background-color: rgb(212, 224, 229) !important;
}
.form-switch .form-check-input:checked {
  background-color: #ffc800;
}
.transfer-wrap-slider .close {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  height: 40px;
  justify-content: center;
  margin: auto;
  position: absolute;
  right: 10px;
  width: 40px;
  z-index: 2;
  top: 10px;
}
.transfer-wrap-slider .close:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%279%27 height=%279%27%3E%3Cpath fill=%27%23FFF%27 fill-rule=%27evenodd%27 d=%27M9 .912 5.412 4.5 9 8.088 8.088 9 4.5 5.412.912 9 0 8.088 3.588 4.5 0 .912.912 0 4.5 3.588 8.088 0z%27/%3E%3C/svg%3E")
    no-repeat;
  background-size: contain;
  content: "";
  display: block;
  right: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  position: absolute;
  padding: 0;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.h-screen {
  height: 100vh;
}

.icon-parlay:before {
  content: "";
}

.icon-parlay-list:before {
  content: "";
}

.icon-parlay-up:before {
  content: "";
}
.betbtn1.betSlipBtn .apana-icon {
  width: 20px;
  height: 20px;
}
.betSlipBtn {
  top: auto;
  background-color: #ffc800;
  bottom: 6rem !important;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.betSlipBtn span {
  position: absolute;
  top: 10%;
  font-size: 8px;
  left: 60%;
  background-color: red;
  color: white;
  padding: 1px 4px;
  border-radius: 50%;
}
.bg-black-100 {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.bg-black-300 {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  /* border-radius: 0.5rem; */
  --tw-bg-opacity: 1;
  background-color: rgb(212 224 229 / var(--tw-bg-opacity));
}
.text-black-300 {
  --tw-text-opacity: 1;
  color: rgb(212 224 229 / var(--tw-text-opacity));
}
.bg-black-600 {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(111 136 152 / var(--tw-bg-opacity));
}
.text-black-400 {
  --tw-text-opacity: 1;
  color: rgb(156 177 189 / var(--tw-text-opacity));
  padding-bottom: 0.2857142857142857rem;
  padding-top: 0.2857142857142857rem;
}
.icon-triangle-black-400 {
  border-color: transparent transparent transparent #9cb1bd;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  height: 0;
  margin: 0 0.2857142857rem;
  width: 0;
  font-size: 0.5714285714285714rem;
}
.text-black-600 {
  font-size: 1.2857142857142858rem;
  --tw-text-opacity: 1;
  color: rgb(111 136 152 / var(--tw-text-opacity));
}
.flex {
  display: flex;
}
.bg-button-normal {
  background-color: var(--color-button-normal);
}
.disabled\:bg-black-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(212 224 229 / var(--tw-bg-opacity));
}
.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}
.rounded-md {
  border-radius: 0.375rem;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.active-sportBook {
  border: 1px solid black !important;
  background-color: rgb(238, 246, 251) !important;
}
.newPlaceButton:disabled {
  background-color: rgb(212, 224, 229) !important;
}

.spark-parly {
  animation: sparkParly 0.6s ease;
}

@keyframes sparkParly {
  0% {
    background-color: #72bbef;
    opacity: 0.6;
    color: #1e1e1e;
  }

  50% {
    background-color: #f8e71c;
    color: #fff;
  }

  100% {
    background-color: #72bbef;
    opacity: 0.6;
    color: #1e1e1e;
  }
}

.sport-accordian .accordion-body {
  padding: 0 !important;
}

.sport-accordian .accordion-body .sport-accord-body {
  padding: 0;
}

.submatchlist {
  border-bottom: #d4e0e5 thin solid;
}
.main-list .submatchlist:last-child {
  border-bottom: none;
}
.submatchlist .text-black-600 {
  font-size: inherit;
  --tw-text-opacity: 1;
  color: inherit;
  margin-left: 10px;
}
.submatchlist.match-list ul li:last-child {
  border-bottom: none;
}

.submatchlist.match-list ul li {
  padding: 0px 26px 10px 0px !important;
  font-size: 0.9285714286rem;
  /* display: flex; */
  gap: 5px;
}
.submatchlist.match-list ul {
  background: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem !important;
  padding-bottom: 0 !important;
}
.Biuparrow svg {
  width: 1.3rem;
  height: 1.3rem;
}
.mb-8 {
  margin-bottom: 80px !important;
}
.deposit-header {
  display: flex;
  justify-content: space-between;
  background-color: black;
  padding: 8px 5px;
}
.deposit-header span {
  color: white;
  font-size: 1rem;
  display: flex;
  justify-content: end;
  width: 50%;
}
.depsit-inner-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  background-color: rgb(212 224 229/1);
}

.depsit-inner-header span {
  font-size: 0.9285714285714286rem;
  font-weight: 700;
}
.depsit-inner-header span:first-child {
  margin-right: 20px;
}

.tab-indicator {
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 47px;
  border-radius: 9999px;
  border-width: 2px;
  bottom: 0.375rem;
  position: absolute;
  left: 0;
}

.depsit-inner-header .active {
  border-bottom: 3px solid black;
}

.payment-header {
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.deposit-payment {
  padding: 5px 15px;
}
.payment-option {
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  gap: 0.3rem;
  margin-top: 5px;
  display: grid;
  grid-template-columns: auto auto auto;
}
.payment-option .active {
  background-color: #ffc800;
  border: none;
}
.payment-option div {
  border-radius: 0.2857142857142857rem;
  line-height: 1rem;
  border: 1px solid rgb(126 145 158/1);
  width: 7.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 4.142857142857143rem;
}
.payment-option span {
  font-weight: 700;
  font-size: 12px;
  margin-top: 3px;
}
.payment-option div img {
  width: 30%;
}
.payment-input {
  margin-top: 5px;
  width: 100%;
  border-radius: 10px;
  padding: 6px 6px;
  background-color: white;
}
.payment-input input {
  width: 100%;
  border-radius: 10px;
  background-color: rgb(212 224 229/1);
  border: none !important;
  height: 2.857142857142857rem;
  text-align: right;
}

.payment-min-max {
  font-size: 0.7857142857142857rem;
  color: rgb(111 136 152/1);
}
.payment-min-max-new {
  margin-top: 3px;
  font-size: 1rem;
  color: rgb(111 136 152/1);
}
.payment-input-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.payment-input-option span {
  margin-right: 3px;
  background-color: rgb(212 224 229/1);
  width: 100%;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(111 136 152/1);
  font-weight: 600;
}
.deposit-accordian {
  margin-top: 10px;
}
.deposit-accordian .accordion-header button {
  font-weight: 600;
  font-size: 14px;
  padding: 8px 10px;
}
.deposit-accordian .accordion-item {
  border: none !important;
}
.deposit-accordian .accordion-item .accordion-header {
  border-radius: 4px !important;
}
.deposit-accordian
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
}
.list-decimal {
  font-size: 0.8571428571428571rem;
  line-height: 1.5rem;
}
.deposit-submit {
  background-color: rgb(212 224 229/1);
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem;
  height: 2.857142857142857rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  color: rgb(111 136 152/1);
}

.deposit-accordian .accordion-item .accordion-header .accordion-button::after {
  content: none;
}

.deposit-accordian .accordion-item .accordion-header .accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deposit-accordian-icon {
  box-shadow: 0 2px 4px #0000000f;
  width: 2.357142857142857rem;
  border-radius: 0.2857142857142857rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5714285714285714rem;
}

.withdraw-header {
  display: flex;
  background-color: white;
  border-radius: 4px;
  padding: 5px 13px;
  margin: 4px 18px;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.withdraw-header div {
  display: flex;
  width: 87%;
  border-right: 1px solid rgb(212 224 229/1);
  flex-direction: column;
  align-items: flex-start;
}

.slider-nav {
  display: flex;
  align-items: center;
}
.slider-arr {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  width: 55px;
  margin-right: 27px;
}
.slider-progress-wrap {
  position: relative;
  flex: 1 1 auto;
}
.slider-progress {
  position: absolute;
  top: 14px;
  right: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: gray;
}

.login-number {
  position: absolute;
  bottom: 4%;
  font-size: 28px;
  color: white;
  font-weight: 600;
  letter-spacing: 1px;
  right: 6%;
}

.skeletion-new {
  background-color: rgb(238 246 251/1);
  border-radius: 0.375rem;
  padding: 5px;
  border: 1px solid rgb(212 224 229/1);
  box-shadow: var(0 0 #0000, 0 0 #0000), var(0 0 #0000, 0 0 #0000),
    var(0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1));
}
.skeletion-inner {
  background-image: linear-gradient(
    90deg,
    #d4e0e5 25%,
    #e6e6e6 37%,
    #d4e0e5 63%
  );
}

.fave .animate {
  background-position: -3519px 0;
  transition: background-position 0.8s steps(55);
}
.fave {
  background-position: 0 0;
  height: 3.2142857143rem;
  margin: -0.9375rem;
  max-height: 45px;
  max-width: 70px;
  /* transform: scale(0.4363636363636363); */
  transform-origin: center center;
  width: 5rem;
}

.fav .overlay-match {
  width: 102 !important;
}

.lobby {
  width: 480px;
  height: 100%;
  border-radius: 35px 35px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/lobby-bg.png");
  position: fixed;
  bottom: 0;
  top: auto;
  height: 32vh;
  z-index: 9999999999999999;
}

.game-grid {
  animation: slideToLeft2 0.6s ease-out;
}

@keyframes slideToLeft2 {
  0% {
    transform: translate(40px);
  }

  100% {
    transform: translate(0);
  }
}

.gmnheader {
  display: flex;
  align-items: start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  background: linear-gradient(-180deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  border-radius: 35px 35px 0 0;
  font-weight: 700;
}

/* .header__img__title .icon-promote {
  width: 44vw;
  height: 12vw;
} */

.header__img {
  background-color: #d9bf44;
  width: 40%;
  border-top-left-radius: 18px;
}
.header__close img {
  height: 3vw;
}

.header__number {
  color: black;
  /* background-color: #574b14; */
  height: 100%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 20%; */
  top: 0;
  color: #d9bf44;
  position: absolute;
  left: 35%;
  font-size: 16px;
}

.game-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 250px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 12px;
  min-height: 100%;
  padding-top: 0 !important;
}
/* .game-card {
  background-color: #2c2c2c;
  border-radius: 10px;
  overflow: hidden;
} */

.game-image {
  /* width: 100%; */
  height: 110px;
  display: block;
  border-radius: 18px;
  max-width: 180px;
}

.game-info {
  height: 50px;
  padding: 10px;
}

.game-title {
  margin: 0;
  font-weight: 800;
  font-size: 1em;
  color: black;
  padding-top: 10px;
}
.game-card-casino {
  background: white;
  position: relative;
  padding: 3px;
  border-radius: 5px;
  margin-top: -25px;
}
.coin-info {
  position: absolute;
  top: 58%;
  background: black;
  color: #d9bf44;
  font-weight: 700;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  left: 5%;
  align-items: center;
  padding: 3px 6px;
  border: 3px solid rgb(255, 255, 255);
}

.coin-info svg {
  /* display: inline-flex;
    position: relative; */
  /* left: 6px;
    top: 3px; */
  width: 18px;
  height: 18px;
  background: linear-gradient(
    -180deg,
    rgb(235, 221, 132) 0%,
    rgb(207, 174, 33) 100%
  );
  border-radius: 30px;
  color: black;
  padding: 2px;
  margin-right: 3px;
}

.game-card-casino-new {
  transform: scale(0.9) !important;
  transition-timing-function: ease;
  transition-duration: 1s;
  background-color: #ffe262;
}
.calendar-market {
  min-height: 400px !important;
  /* top: 57%; */
  height: auto !important;
}
.calendar-market-during {
  /* top: 89% !important; */
  min-height: 90px !important;
}
.calendar-market .market-title h4 {
  color: white !important;
}

.casino-play-inner {
  height: 100%;
}

.game-grid-new {
  min-height: 100%;
  height: 240px !important;
  padding-top: 0 !important;
}

.loading1 {
  background-color: #a6a6a6;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  padding: 30px;
  /* text-align: center; */
}
.loading1Outer {
  height: 278px !important;
}

.listPage {
  height: 278px;
}
.loading-box1 {
  background: rgba(27, 31, 35, 0.7);
  border: 1px solid #262c32;
  width: 7rem;
  border-radius: 0.5rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 45px auto;
}
.sideicon li img {
  width: 25px;
  opacity: 0.6;
  height: 25px !important;
}
.sideicon li.active img {
  width: 25px;
  opacity: 0.9;
  height: 25px !important;
}

.spark-sport {
  animation: sparkSport 0.4s ease;
}

@keyframes sparkSport {
  0% {
    background-color: rgb(207, 174, 33);
    color: #1e1e1e;
  }

  50% {
    background-color: rgb(235, 221, 132);
    color: #fff;
  }

  100% {
    background-color: rgb(207, 174, 33);
    color: #1e1e1e;
  }
}

.is-outsite-icon-new {
  align-items: center;
  background: #fff;
  border: 1px solid #ffffff4d;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  padding: 6px 4px;
  position: absolute;
  right: 2%;
  top: 4%;
  width: 15%;
  z-index: 9;
}

.games-slot .slot1.active {
  background: #45494d;
}
.games-slot .slot1.activenew span {
  color: #ffc800;
}
.slotactive {
  animation: sparkSportLot 0.1s ease;
}

@keyframes sparkSportLot {
  0% {
    background-color: #235a70;
    opacity: 0.9;
  }

  50% {
    background-color: #235a70;
    opacity: 0.8;
  }

  100% {
    background-color: #235a70;
    opacity: 1;
  }
}
/*
.spark-sport-slot {
  animation: sparkSportLot 0.4s ease;
}

@keyframes sparkSportLot {
  0% {
    background-color: rgb(27 31 35/1);
    opacity: 1;
  }

  50% {
    background-color: rgb(27 31 35/1);
    opacity: .6;
  }

  100% {
    background-color: rgb(27 31 35/1);
    opacity: 0;
  }
} */

.betbtn3 {
  bottom: 16%;
  position: fixed;
  left: 5%;
  border-radius: 50%;
  background: #ffc800;
  width: 3.2142857142857144rem !important;
  height: 3.2142857142857144rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.betbtn4 {
  bottom: 20%;
  position: fixed;
  right: 5%;
  border-radius: 50%;
  background: #9cb1bd;
  width: 43px !important;
  height: 43px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  opacity: 1;
  font-weight: 700;
  animation: sparkBackAnimation 0.4s ease;
}

@keyframes sparkBackAnimation {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.betbtn4 span {
  font-size: 0.8571428571428571rem;
  font-weight: 700;
}
.betbtn4 .p-0 {
  margin-bottom: -5px !important;
}
.betbtn3 .apana-icon {
  width: 25px;
  height: 25px;
}

.betbtn3 span {
  position: absolute;
  top: 10%;
  font-size: 8px;
  left: 60%;
  background-color: red;
  color: white;
  padding: 1px 4px;
  border-radius: 50%;
}

.parly-odds {
  font-weight: 800;
  font-size: 1.0714285714285714rem;
  width: 3.4285714285714284rem;
  height: 1.9285714285714286rem;
  padding-top: 2px;
}

.parly-event-name {
  font-weight: 700;
  font-size: 1.0714285714285714rem;
}

.sport-parly-list {
  padding: 10px;
}
.sport-parly-list .accordion {
  margin-bottom: 10px !important;
}
.sport-parly-list .accordion .accordion-header {
  background: white;
  border-radius: 10px;
}

.sport-parly-list .accordion .accordion-header .accordion-button {
  /* background: white; */
  border-radius: 10px;
}

.parly-list-page {
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 0.2rem !important;
  padding-top: 2px;
  /* padding: 4px !important; */
}
.parly-list-page .parly-list-page-inner div {
  border-radius: 3px;
  height: calc(8.57142857143rem / 2);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(212 224 229/1);
}
.parly-list-page-inner .suspended {
  position: absolute;
  width: 49%;
  background: rgb(90 94 98/1);
  opacity: 0.8;
  border-radius: 4px;
  bottom: 0;
  font-size: 0.7857142857rem;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  left: 0;
  width: 100%;
  height: 100%;
}
.parly-list-page .parly-soccer div {
  height: calc(8.57142857143rem / 3) !important;
}
.parly-list-page .parly-list-page-inner div span {
  text-align: center;
  text-overflow: ellipsis;
}
.parly-list-page .active {
  border: 1.4px solid black;
  background-color: rgb(238, 246, 251);
}

.parly-slider .slick-list .slick-track .slick-slide > div {
  margin: 0 !important;
}

.parly-slider .slick-dots li {
  padding: 0;
  margin: 0;
  width: 15px !important;
}

.parly-slider .slick-dots li button:before {
  line-height: 5px;
}

.parly-slider .slick-dots li.slick-active button:before {
  background: #c69700 !important;
}
.parly-section-header::after {
  width: 1.2px;
  height: 12px;
  content: "";
  position: absolute;
  right: 50%;
  background-color: black;
  /* top: 10%; */
  margin-top: 5px;
}

.parly-list-page .parly-list-page-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
  position: relative;
  flex-direction: column;
  /* height: 4.285714285714286rem; */
  height: 8.57142857143rem;

  width: 49%;
}
/* .parly-list-page-inner{
  height: 4.285714285714286rem + 4.285714285714286rem;
} */
.parly-list-page .parly-list-page-inner strong {
  line-height: 10px;
  margin: 0;
  padding: 0;
  font-size: 1.0714285714285714rem;
}

.parly-list-page .parly-list-page-inner span {
  font-size: 0.7rem;
}

.parly-list-page-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 4px;
  width: 100%;
  background: #ffc800;
  height: 23px;
}

.setting input {
  background: #a2c1d0;
  box-shadow: inset 0 3px #00000026;
  transition: all 0.4s ease;
}

.md-switch {
  margin-bottom: 15px;
}
.md-switch:last-of-type {
  margin-bottom: 0px;
}
.md-switch .md-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.md-switch .md-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.md-switch input.md-toggle-round + label {
  padding: 2px;
  width: 68px;
  height: 40px;
}
.md-switch input.md-toggle-round + label:before,
input.md-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
.md-switch input.md-toggle-round + label:before {
  right: 1px;
  background: #dddddd;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
  border-radius: 60px;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}
.md-switch input.md-toggle-round + label:after {
  width: 34px;
  height: 34px;
  margin: auto 4px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
  border-radius: 100%;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}
.md-switch input.md-toggle-round:checked + label:before {
  background-color: #ffc800;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
}
.md-switch input.md-toggle-round:checked + label:after {
  margin-left: 32px;
}
.md-switch input.md-toggle-round:checked + label span {
  color: #555555;
}
.md-switch label span {
  position: absolute;
  top: 10px;
  display: block;
  font-size: 18px;
  padding-left: 15px;
  box-sizing: border-box;
  min-width: 500px;
  height: 40px;
  margin-left: 65px;
  vertical-align: middle;
  color: #555555;
}

/* .md-switch input:disabled + label:before {
  background-color:#ddddd;
  cursor: not-allowed;
} */
.md-switch input:disabled + label span {
  color: #cccccc;
  cursor: not-allowed;
}
.md-switch input:disabled + label:after {
  background-color: #cccccc;
  cursor: not-allowed;
}

.md-switch.md input.md-toggle-round + label {
  width: 46px;
  height: 25px;
}
.md-switch.md input.md-toggle-round + label:after {
  width: 21px;
  height: 21px;
}
.md-switch.md input.md-toggle-round:checked + label:after {
  margin-left: 24px;
}
.md-switch.md label span {
  margin-left: 45px;
  line-height: 10px;
}

.md-switch.sm input.md-toggle-round + label {
  width: 35px;
  height: 18px;
}
.md-switch.sm input.md-toggle-round + label:after {
  width: 14px;
  height: 14px;
}
.md-switch.sm input.md-toggle-round:checked + label:after {
  margin-left: 20px;
}
.md-switch.sm label span {
  margin-left: 35px;
  line-height: 0px;
}

.sideicon li i {
  font-size: 1.9285714285714286rem;
  color: rgb(90 94 98/1);
  font-weight: 700;
}
.sideicon li.active i {
  font-size: 1.9285714285714286rem;
  color: #1b1f23;
}
.social-header i {
  margin-right: 0.375rem;
}
.text-icon-light {
  color: rgb(#fff / 1);
  font-size: 1.4285714285714286rem;
}
.form-switch .form-check-input {
  background-image: url("../images/parlay-check.svg") !important;
}
.game-menu li i {
  font-size: 1.2142857142857142rem;
  margin-right: 0.25rem;
}

.rotate-90,
.scale-110 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
}
.divtriangle {
  position: absolute;
  font-size: 0.42rem;
}

i.icon-triangle.divtriangle {
  position: absolute;
  right: 8px;
  top: 12px;
}
.all-complete .dropdown-toggle::after {
  display: none;
}
.all-complete .dropdown {
  width: 39%;
}
/* .all-complete .dropdown-trigger {
  width: 100%;
}
.all-complete  button {
  width: 100%;
} */

i.icon-toggle.rotate-90 {
  margin-right: 5px;
}

.-rotate-90 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
}
.divtriangle {
  position: absolute;
  font-size: 0.42rem;
}
.text-black-500 {
  color: rgb(126 145 158/1);
}

.sidebar-wrapper .icon {
  font-size: 1.1428571428571428rem;
  margin-right: 0.5rem;
}
.new-game-slot .icon {
  font-size: 3rem;
  color: #ffffff73;
}
.games-slot .slot1.activenew .icon {
  color: #ffc800;
}
.form-check-input:focus {
  box-shadow: none !important;
}
.top-sidebar .icon {
  font-size: 14px;
}
.gap-12 {
  gap: 12px;
}
.text-header-icon {
  font-size: 1.4285714285714286rem;
  margin-right: 0.625rem;
}
.mark-depth-total {
  padding: 0.5rem 0;
  background-color: #fff;
}
.mark-depth-total table {
  margin-bottom: 0 !important;
}
.text-right {
  text-align: right;
}

.update-p .form-floating > label {
  color: #6f8898;
  font-weight: 500;
}

.update-p .form-floating .form-control {
  /*  */
  border: 1px solid #6f8898;
  padding: 0.5rem 0.5rem;
  height: 2.857142857142857rem;
  min-height: 2.857142857142857rem;
  border-radius: 0.5rem;
}

.update-p .form-floating > .form-control:focus ~ label {
  opacity: 1 !important;
  transform: translateY(-1.5rem) translateX(0.15rem);
  max-width: max-content;
  background: #fff;
  padding: 0px 3px;
  height: auto;
  margin-left: 14px;
  font-size: 16px;
  color: #c0a024 !important;
}
.video-contaienr {
  min-height: 15rem;
  background-color: #000000;
}
.icon-parlay-list {
  font-size: 1.5714285714285714rem;
}

.fancy-list li {
  padding-left: 3px !important;
}

.single-match-body .bet-name.lay {
  background: #fcc6d1;
  margin-right: 8px;
  font-size: 0.9285714285714286rem;
}
.single-match-body .bet-name.back {
  margin-right: 8px;
}
.single-match-body.current-bets-table .lay-back td {
  background: #eef6fb;
  font-weight: 700;
  padding: 0.5rem;
  font-size: 0.9285714285714286rem;
}

.single-match-body.current-bets-table td .d-flex {
  color: #6f8898;
  padding: 0;
}
.single-match-body.current-bets-table td {
  line-height: inherit;
}

.single-match-body-mybets {
  position: relative;
  z-index: 999999;
  margin-top: -23px;
  border-radius: 0.75rem 0.75rem 0 0 !important;
}
.single-match-body-mybets-modal .market-title {
  padding-bottom: 35px;
}
.mybets-text {
  position: relative;
  z-index: 999999;
  margin-top: -23px;
  border-radius: 0.75rem 0.75rem 0 0 !important;
  background: rgb(212 224 229/ 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
}
.mybets-text + .single-match-body-mybets {
  margin-top: 0;
}

.single-match-body-mybets-modal .single-match-body-mybets {
  margin-top: 0px;
}

.single-match-body-mybets-modal .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: #fff;
  box-shadow: none;
  padding: 0.5rem;
}
.single-match-body-mybets-modal .master-pass.first {
  font-size: 0.9285714285714286rem;
  color: #000;
  font-weight: 700;
}
.single-match-body-mybets-modal .master-pass {
  font-weight: 400;
}
.single-match-body-mybets-modal .master-pass::before {
  border-color: transparent transparent transparent #d4e0e5;
}

.single-match-body-mybets-modal .accordion-button::after {
  background-size: 70%;
  border: #d4e0e5 thin solid;
  border-radius: 0.25rem;
  width: 1.75rem;
  height: 1.75rem;
  background-position: center center;
}
.single-match-body-mybets-modal .mybets-text {
  font-size: 0.9285714285714286rem;
  font-weight: 700;
}
.single-match-body-mybets-modal .bg-dark.text-white {
  padding: 0.25rem 0.375rem !important;
  font-size: 0.7142857142857143rem !important;
  line-height: 12px !important;
  border-radius: 0.25rem;
  font-weight: 500;
}

.mybets-text-title {
  border-bottom: 4px solid rgb(0, 0, 0);
  padding-bottom: 4px;
  margin-right: 20px;
}
.single-match-body-mybets-modal .single-match-body {
  height: auto;
  overflow-y: auto;
  background: #e2eaef;
  margin: 0;
  border-radius: 0;
}
.single-match-body-mybets-modal .accordion-button .bg-yellow.px-2 {
  font-size: 0.7142857142857143rem !important;
  border-radius: 0.25rem !important;
  width: 1.5rem;
  height: 1.25rem;
  display: inline-flex;
  line-height: 10px;
}

.single-match-body-mybets-modal .accordion-button div {
  display: flex;
  align-items: center;
}

.single-match-body-mybets-modal .accordion-button {
  padding: 0.5rem;
}

.single-match-body-mybets-modal .dropdown-menu.show {
  z-index: 9999999;
}

.accordion {
  --bs-accordion-color: inherit !important;
}

.stickyLeftContainer {
  position: fixed;
  bottom: 0;
  padding: 0.5rem;
  gap: 0.5rem;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
}

.stickyLeftContainer .betbtn3 {
  position: relative;
  bottom: 0;
  left: 0;
}

.stickyLeftContainer span.icon-list {
  position: relative;
  bottom: 0;
  left: 0;
}
.stickyLeftContainer.stickyLeftContainer2 {
  bottom: 71px;
}

.single-match-body-parlaymybets.market-depth-body table td .parlays {
  font-size: 0.9285714285714286rem;
}

.single-match-body-parlaymybets .bet-name {
  padding: 6px 17px;
  border-radius: 22px;
  color: #000;
  margin-right: 0px;
  font-weight: 700;
  width: 3.5714285714285716rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  gap: 0.3rem;
  font-size: 0.9285714285714286rem;
}

.single-match-body-parlaymybets .rounded-circle {
  width: 0.2857142857142857rem;
  height: 0.2857142857142857rem;
  border-radius: 9999px;
  background-color: #6f8898;
  display: inline-flex;
  flex-direction: row;
  gap: 6px;
}
.single-match-body-parlaymybets .show-hide-bet svg {
  font-size: 18px;
  transition: 0.4s;
  margin-top: -15px;
}

.single-match-body-parlaymybets .show-hide-bet {
  background: #d4e0e5 !important;
  border: none;
  padding: 2px 47px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px 0px 14px 14px;
  height: 18px;
  margin-top: -2px;
}
.msgbox-w {
  color: #fff !important;
}
.widgetAttacker {
  filter: drop-shadow(0 0 2px #e20000);
  background-color: #e20000;
  width: 0.2857142857142857rem;
  height: 0.2857142857142857rem;
  margin-left: -3.714286rem;
}
.widget-track {
  align-items: center;
  position: relative;
}

.profit-tr td {
  border-width: 0 !important;
  font-size: 0.8571428571428571rem !important;
}
.sport-header-inner-new {
  overflow-x: scroll;
  padding: 0 15px;
  background-color: rgb(212 224 229/1);
  flex-wrap: nowrap;
  display: flex;
  overflow-y: hidden;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  scrollbar-width: none;
}
.sport-header-inner-new div {
  margin-right: 20px;
  position: relative;
}
.sport-header-inner-new div h3 {
  font-size: 0.9285714285714286rem;
  font-weight: 700;
}

.sport-header-inner-new div h3.active::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 20px;
  right: 0;
  margin: 0 auto;
  border-radius: 0.15rem;
  width: 100%;
  background: #1e1e1e;
  height: 0.29rem;
}

.fancy-header-new {
  overflow-x: scroll;
  padding: 0 15px;
  background-color: #ffc800;
  flex-wrap: nowrap;
  display: flex;
  overflow-y: hidden;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  scrollbar-width: none;
  padding-bottom: 3px;
}
.fancy-header-new div {
  margin-right: 20px;
  position: relative;
}
.fancy-header-new div h3 {
  font-size: 0.9285714285714286rem;
  width: max-content;
  font-weight: 700;
  padding: 0 1rem;
}

.fancy-header-new div h3.active::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 20px;
  right: 0;
  margin: 0 auto;
  border-radius: 0.1rem;
  width: 100%;
  background: #1e1e1e;
  height: 0.2rem;
}

.top-list-move {
  transition: all 0.5s ease-in-out;
}

.my-profile tbody tr td {
  font-size: 0.8571428571428571rem;
  padding: 0.3rem 0.5rem !important;
}
.my-profile tbody tr td:nth-child(2) {
  color: rgb(111 136 152/1);
}

.active-log-table table tbody tr td {
  font-size: 0.8571428571428571rem !important;
  padding: 0.3rem 0.5rem !important;
  border-width: 0;
}
/* .active-log-table table tbody tr td:last-child{
  border-width: 0 !important;
} */

.fancy-list-first-inner {
  width: 100% !important;
  text-align: left !important;
  gap: 0 !important;
  align-items: center;
}

.parlay-inner-tab li {
  position: relative;
}
.parlay-inner-tab li.active::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 25px;
  right: 0;
  width: 100%;
  z-index: 99999;
  background: white;
  border-radius: 0.1rem;
  height: 0.2rem !important;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.h4-sport {
  margin-right: 5px !important;
  font-size: .9285714285714286rem !important;
  color: #8b6b05 !important;
}


.rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.first-step {
  height: 100vh;
  padding:0 .25rem;
}

.first-step h2 {
  color: #fff !important;
  text-align: center;
  width: 100%;
  font-size: 16px;
  height: 2.857142857142857rem;
  margin: 0;
  line-height: 2.857142857142857rem;
}

.first-step .modal-content {
  background: #fff;
  height: calc(100vh - 44px);
  border-radius: .75rem .75rem 0 0;
  padding:2rem;     overflow-y: auto;
}


.first-step .modal-content h1{
  font-size: 1.5714285714285714rem;
  font-weight: 700;
  line-height: 2rem;
  border-bottom: #d4e0e5 thin solid;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.first-step-content {
  position: absolute;
  bottom: 0;
  background: #eef6fb;
  width: calc(100% - .50rem);
  left: auto;
  right: auto;
  margin: auto;
  padding:12px;
  text-align: center;
}
.first-step-btn {
  display: flex; gap: 10px;
  justify-content: space-between;
}

.first-step-btn button {
  width: 100%;
  background-color: transparent;
  border-color: #7e919e;
  border-width: 1px;
  font-weight: 700;
  color: #1e1e1e;
  font-size: 16px;
  height: 2.857142857142857rem;
}

  .first-step-btn button:last-child { background:#ffc800; border-color:#ffc800;}

  .step-check {
    font-size: 16px;
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 15px;
}

.first-step .pop-page-full__box h2 {
  color: #000 !important;
  text-align: left;
  width: 100%;
  font-size: 1.2857142857142858rem;
  height: auto;
  margin: 0;
  line-height: inherit;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.first-step .pop-page-full__box h3, .first-step .pop-page-full__box h4 {
  font-size: 1.1428571428571428rem;
  line-height: 24px;
  margin-bottom: .8571428571428571rem;
  margin-top: .8571428571428571rem; font-weight: 700;
}

.first-step {
  height: 100vh;
  padding:0 .25rem;
}

.first-step h2 {
  color: #fff !important;
  text-align: center;
  width: 100%;
  font-size: 16px;
  height: 2.857142857142857rem;
  margin: 0;
  line-height: 2.857142857142857rem;
}

.first-step .modal-content {
  background: #fff;
  height: calc(100vh - 44px);
  border-radius: .75rem .75rem 0 0;
  padding:2rem;     overflow-y: auto;
}


.first-step .modal-content h1{
  font-size: 1.5714285714285714rem;
  font-weight: 700;
  line-height: 2rem;
  border-bottom: #d4e0e5 thin solid;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.first-step-content {
  position: absolute;
  bottom: 0;
  background: #eef6fb;
  width: calc(100% - .50rem);
  left: auto;
  right: auto;
  margin: auto;
  padding:12px;
  text-align: center;
}
.first-step-btn {
  display: flex; gap: 10px;
  justify-content: space-between;
}

.first-step-btn button {
  width: 100%;
  background-color: transparent;
  border-color: #7e919e;
  border-width: 1px;
  font-weight: 700;
  color: #1e1e1e;
  font-size: 16px;
  height: 2.857142857142857rem;
}

  .first-step-btn button:last-child { background:#ffc800; border-color:#ffc800;}

  .step-check {
    font-size: 16px;
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 15px;
}

.first-step .pop-page-full__box h2 {
  color: #000 !important;
  text-align: left;
  width: 100%;
  font-size: 1.2857142857142858rem;
  height: auto;
  margin: 0;
  line-height: inherit;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.first-step .pop-page-full__box h3, .first-step .pop-page-full__box h4 {
  font-size: 1.1428571428571428rem;
  line-height: 24px;
  margin-bottom: .8571428571428571rem;
  margin-top: .8571428571428571rem; font-weight: 700;
}